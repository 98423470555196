import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Offer as OfferLayout,
  Sale as SaleLayout,
} from "./layouts";
import { userRole } from "./common/roles";

import {
  Dashboard as DashboardView,
  Account as AccountView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  EmailVerification as EmailVerificationView,
  SessionDetails as SessionDetailsView,
  //CreateSession as CreateSessionView,
  //EditSession as EditSessionView,
  Search as SearchView,
  VideoUpload as VideoUploadView,
  //Welcome as WelcomeView,
  Help as HelpView,
  Privacy as PrivacyView,
  TermsOfService as TOSView,
  //RoleReview as RoleReviewView,
  Invites as InvitesView,
  Plans as PlansView,
  /*ReportedIssues as ReportedIssuesView,
  Labeling as LabelingDetailsView,
  CreateLabelingRequest as CreateLabelingRequestView,
  CreateLabelingJob as CreateLabelingJobView,
  EditLabelingRequest as EditLabelingRequestView,
  EditLabelingJob as EditLabelingJobView,*/
  Students as StudentsView,
  Learning as LearningView,
  Summary as SummaryView,
  SummaryGraphs,
  //Coupons as CouponsView,
  ContentManagement as ContentManagementView,
  //Reports as ReportsView,
  CustomTokenAuth as CustomTokenAuthView,
  OpenApp as OpenAppView,
  RemoteControlSession as RemoteControlSessionView,
  PracticeGuide as PracticeGuideView,
  QuestionnairesStatistics as QuestionnairesStatisticsView,
} from "./views";
import { Enterprise as EnterpriseView } from "./views/enterprise";
import { Studio as StudioView } from "./views/studio";
import { StudioCoach as StudioCoachesView } from "./views/studio/coach";
import { Bootcamp as BootcampView } from "./views/bootcamp";
import { EnterpriseInstructors as EnterpriseInstructorsView } from "./views/enterprise/instructors";
import { EnterprisePay as EnterprisePayView } from "./views/enterprise/pay";
import { EnterprisePayStudents as EnterprisePayStudentsView } from "./views/enterprise/payStudents";
import { Invoices as InvoicesView } from "./views/invoices";
import { Offer as OfferView } from "./views/offer";
import { Offer as InvitationalView } from "./views/invitational";
import { PromoCheckout as PromoCheckoutView } from "./views/promo_checkout";
//import { Program2023 as Program2023View } from "./views/program2023";
import { SpeedAssessment as SpeedAssessmentView } from "./views/speed_assessment_offer";
import { Lesson as LessonView } from "./views/lesson";
import { UnityPage as UnityView } from "./views/unity";
import { LLMChatPage as LLMChatView } from "./views/llm_chat";
import { LLMLlamoChatPage as LLMLlamoChatView } from "./views/llm_llamo";
import { WeeklyUserReportPage } from "./views/weekly_user_report/WeeklyUserReport";
import { Credits as CreditsView } from "./views/credits";
import { CreditsHistory as CreditsHistoryView } from "./views/credits/history/CreditsHistory";
import {
  StudentsDashboardTablePage,
  StudentsTablePage,
} from "./views/student_dashboard/";
import { Apps as AppsView } from "./views/apps/Apps";
import { ENVIRONMENT } from "./common/envConfig";
import { AccountDetailsView } from "./views/account_details/page";
import { MainPage } from "./views/mainpage/page";
import { SwingLibrary } from "./views/swing_library";
import { OfferPaid } from "./views/offer_paid";
import { PdfWithCSS } from "./views/ztest";
import RoastMySwing from "./views/roast-my-swing";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <RouteWithLayout
        component={MainPage}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={AccountDetailsView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={PlansView}
        exact
        layout={SaleLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={OfferView}
        exact
        layout={OfferLayout}
        path="/offer"
      />
      <RouteWithLayout
        component={InvitationalView}
        exact
        layout={OfferLayout}
        path="/invitational"
      />
      <RouteWithLayout
        component={InvitationalView}
        exact
        layout={OfferLayout}
        path="/swing-analysis-offer"
      />
      <RouteWithLayout
        component={InvitationalView}
        exact
        layout={OfferLayout}
        path="/golfpad"
      />
      <RouteWithLayout
        component={InvitationalView}
        exact
        layout={OfferLayout}
        path="/metagolfclub"
      />
      <RouteWithLayout
        component={InvitationalView}
        exact
        layout={OfferLayout}
        path="/dollar-driver-club"
      />
      <RouteWithLayout
        component={InvitationalView}
        exact
        layout={OfferLayout}
        path="/ccgolf"
      />
      <RouteWithLayout
        component={OfferPaid}
        exact
        layout={OfferLayout}
        path="/mark-crossfield"
      />
      <RouteWithLayout
        component={OfferPaid}
        exact
        layout={OfferLayout}
        path="/holiday-offer"
      />
      <RouteWithLayout
        component={PromoCheckoutView}
        exact
        layout={OfferLayout}
        path="/promo-checkout"
      />
      <RouteWithLayout
        component={AppsView}
        exact
        layout={OfferLayout}
        path="/golf-genius"
      />
      <RouteWithLayout
        component={AppsView}
        exact
        layout={OfferLayout}
        path="/golf-genius/open-session"
      />
      <RouteWithLayout
        component={AppsView}
        exact
        layout={OfferLayout}
        path="/golf-genius/start-session"
      />
      {/*<RouteWithLayout
        component={Program2023View}
        exact
        layout={OfferLayout}
        path="/NY-2023"
      />*/}
      {
        <RouteWithLayout
          component={SpeedAssessmentView}
          exact
          layout={OfferLayout}
          path="/speed-assessment-human"
        />
      }
      {
        <RouteWithLayout
          component={SpeedAssessmentView}
          exact
          layout={OfferLayout}
          path="/speed-assessment-ai"
        />
      }
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={CustomTokenAuthView}
        exact
        layout={MinimalLayout}
        path="/custom-token-auth"
      />
      <RouteWithLayout
        component={OpenAppView}
        exact
        layout={MinimalLayout}
        path="/open-app"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={ForgotPasswordView}
        exact
        layout={MinimalLayout}
        path="/forgot-password"
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={MinimalLayout}
        path="/reset-password"
      />
      <RouteWithLayout
        component={EmailVerificationView}
        exact
        layout={MinimalLayout}
        path="/email-verification"
      />
      <RouteWithLayout
        component={HelpView}
        exact
        layout={MainLayout}
        path="/help"
      />
      {/*<RouteWithLayout
        component={SessionDetailsView}
        exact
        layout={MainLayout}
        path="/session-details"
      />*/}
      <RouteWithLayout
        component={LearningView}
        exact
        layout={MainLayout}
        path="/learning"
      />
      <RouteWithLayout
        component={StudentsTablePage}
        exact
        layout={MainLayout}
        path="/students"
      />
      {/*<RouteWithLayout
        component={CreateSessionView}
        exact
        layout={MainLayout}
        path="/create-session"
      />
      <RouteWithLayout
        component={EditSessionView}
        exact
        layout={MainLayout}
        path="/edit-session"
      />
      <RouteWithLayout
        component={RoleReviewView}
        exact
        layout={MainLayout}
        path="/role-review"
        allowedRoles={[userRole.ADMIN]}
      />*/}
      <RouteWithLayout
        component={InvitesView}
        exact
        layout={MainLayout}
        path="/invites"
      />
      <RouteWithLayout
        component={CreditsView}
        exact
        layout={MainLayout}
        path="/credits"
      />
      {/*<RouteWithLayout
        component={CreditsHistoryView}
        exact
        layout={MainLayout}
        path="/credits/history"
      />*/}
      <RouteWithLayout
        component={InvoicesView}
        exact
        layout={MainLayout}
        path="/invoices"
      />
      <RouteWithLayout
        component={EnterpriseView}
        exact
        layout={MainLayout}
        path="/enterprise"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={StudioView}
        exact
        layout={MainLayout}
        path="/studio"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={BootcampView}
        exact
        layout={MainLayout}
        path="/bootcamp"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={EnterpriseInstructorsView}
        exact
        layout={MainLayout}
        path="/coaches"
      />
      <RouteWithLayout
        component={StudioCoachesView}
        exact
        layout={MainLayout}
        path="/studio-coaches"
      />
      <RouteWithLayout
        component={EnterprisePayView}
        exact
        layout={MainLayout}
        path="/enterprise/pay"
        //allowedRoles={[userRole.SPORTSBOX_ENTERPRISE, userRole.ADMIN]}
      />
      <RouteWithLayout
        component={EnterprisePayStudentsView}
        exact
        layout={MainLayout}
        path="/enterprise/pay/students"
      />
      {/*<RouteWithLayout
        component={LessonView}
        exact
        layout={MainLayout}
        path="/lesson"
      />*/}
      <RouteWithLayout
        component={PlansView}
        exact
        layout={SaleLayout}
        path="/plans"
      />
      {/*<RouteWithLayout
        component={ReportedIssuesView}
        exact
        layout={MainLayout}
        path="/reported-issues"
        allowedRoles={[userRole.ADMIN]}
      />*/}
      <RouteWithLayout
        component={SummaryView}
        exact
        layout={MainLayout}
        path="/summary"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={SummaryGraphs}
        exact
        layout={MainLayout}
        path="/summary-graphs"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={ContentManagementView}
        exact
        layout={MainLayout}
        path="/content-management"
        allowedRoles={[userRole.ADMIN]}
      />
      {/*<RouteWithLayout
        component={ReportsView}
        exact
        layout={MainLayout}
        path="/reports"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={CouponsView}
        exact
        layout={MainLayout}
        path="/coupons"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={VideoUploadView}
        exact
        layout={MainLayout}
        path="/video-upload"
      />*/}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/welcome"
      />
      <RouteWithLayout
        component={SearchView}
        exact
        layout={MainLayout}
        path="/search"
      />
      <RouteWithLayout
        component={PrivacyView}
        exact
        layout={MinimalLayout}
        path="/privacy"
      />
      <RouteWithLayout
        component={TOSView}
        exact
        layout={MinimalLayout}
        path="/terms-of-service"
      />
      {/*<RouteWithLayout
        component={LabelingDetailsView}
        exact
        layout={MainLayout}
        path="/labeling"
      />
      <RouteWithLayout
        component={CreateLabelingRequestView}
        exact
        layout={MainLayout}
        path="/create-labelingRequest"
      />
      <RouteWithLayout
        component={CreateLabelingJobView}
        exact
        layout={MainLayout}
        path="/create-labelingJob"
      />
      <RouteWithLayout
        component={EditLabelingRequestView}
        exact
        layout={MainLayout}
        path="/edit-labelingRequest"
      />
      <RouteWithLayout
        component={EditLabelingJobView}
        exact
        layout={MainLayout}
        path="/edit-labelingJob"
      />*/}
      <RouteWithLayout
        component={RemoteControlSessionView}
        exact
        layout={MainLayout}
        path="/remote-session"
        //allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={PracticeGuideView}
        exact
        layout={MainLayout}
        path="/practice-guides"
      />
      <RouteWithLayout
        component={QuestionnairesStatisticsView}
        exact
        layout={MainLayout}
        path="/questionnaires"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={WeeklyUserReportPage}
        exact
        layout={MainLayout}
        path="/weekly-reports"
        allowedRoles={[userRole.ADMIN]}
      />
      <RouteWithLayout
        component={UnityView}
        exact
        layout={MainLayout}
        path="/unity"
        allowedRoles={ENVIRONMENT === "PRODUCTION" ? [userRole.ADMIN] : ""}
      />
      <RouteWithLayout
        component={LLMChatView}
        exact
        layout={MainLayout}
        path="/llm-chatgpt"
        allowedRoles={ENVIRONMENT === "PRODUCTION" ? [userRole.ADMIN] : ""}
      />
      <RouteWithLayout
        component={LLMLlamoChatView}
        exact
        layout={MainLayout}
        path="/llm-llamo"
        allowedRoles={ENVIRONMENT === "PRODUCTION" ? [userRole.ADMIN] : ""}
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/student-dashboard"
      />
      <RouteWithLayout
        component={SwingLibrary}
        exact
        layout={MainLayout}
        path="/swing-library"
      />
      {ENVIRONMENT !== "PRODUCTION" && <RouteWithLayout
        component={RoastMySwing}
        exact
        layout={OfferLayout}
        path="/roast-my-swing"
      />}
      <RouteWithLayout
        component={SwingLibrary}
        exact
        layout={MainLayout}
        path="/swing-library"
      />
      <RouteWithLayout
        component={PdfWithCSS}
        exact
        layout={MainLayout}
        path="/t"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
