import React from "react";
import { GeneratePdfButton } from "../swing_library/components/PdfGeneration/GeneratePdfButton";
import { PurchaseHistoryButton } from "../credits/components/PurchaseHistoryButton/PurchaseHistoryButton";

export const PdfWithCSS = () => {
  return (
    <div>
      <GeneratePdfButton
        userId="9o6lNlYzHrVTJs58wmaPVP2wzia2"
        videoIds={[
          "a8aXfFAE6wB9r7X2Nl1X",
          "NH79Dem9nrDX13xY9df4",
          "NH79Dem9nrDX13xY9df4",
        ]}
        golfClub="7I"
        userName="Jane Doe"
        height="5.11"
        gender="Female"
        credits={100}
        verificationClubs={[]}
        verificationUsers={[]}
        verificationCameraAngles={[]}
        verificationAnalysed={[]}
        isTest
      />
    </div>
  );
};
