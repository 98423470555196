import React from 'react';
import { Button, Typography } from '@material-ui/core';

function VideoPlayer({ jsonData, fileIdx, setFileIdx, toggleDetails, setToggleDetails, videoRef, cacheConversation}) {
  const incrementFileIdx = () => {
    if (fileIdx < jsonData.length - 1) {        
      cacheConversation(fileIdx, fileIdx+1)
      setFileIdx((prevIdx) => prevIdx + 1);
    }
  };

  const decrementFileIdx = () => {
    if (fileIdx > 0) {        
      cacheConversation(fileIdx, fileIdx-1)
      setFileIdx((prevIdx) => prevIdx - 1);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '70vh', textAlign: 'center' }}>
      {!toggleDetails && (
        <>
          {/*<Button variant="contained" color="primary" onClick={() => setToggleDetails(!toggleDetails)}>
            Show details
          </Button>*/}
          <video ref={videoRef} style={{ padding: '10px', width: '60%', height: '80%', objectFit: 'contain' }} controls>
            <source src={jsonData[fileIdx].video_url} type="video/mp4" />
          </video>
          <div>
            <Button onClick={decrementFileIdx}>Back</Button>
            {fileIdx + 1} / {jsonData.length}
            <Button onClick={incrementFileIdx}>Next</Button>
          </div>
        </>
      )}
    </div>
  );
}

export default VideoPlayer;
