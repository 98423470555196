import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  icon: {
    width: "34px",
    height: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    background: "#E9DDFD",
  },

  iconFail: {
    width: "34px",
    height: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    background: "#fddddd",
  },
  checkmark: {
    width: "16px",
    height: "16px",
    stroke: "#5B21B6",
  },
  dialogContent: {
    // display: "flex",
    // flexDirection: "column",
    // gap: "20px",
    padding: "0 20px 20px",
    fontSize: "14px",
  },
  link: {
    color: "#5B21B6",
    textDecoration: "none",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "16px",
  },
  closeButton: {
    width: "100%",
    marginTop: theme.spacing(2),
    backgroundColor: "#F3F4F6",
    borderRadius: "64px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#E5E7EB",
    },
  },
}));

const PurchaseResultDialog = ({
  open,
  onClose,
  isSuccessfull,
  credits,
  creditType,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      {/* Title with Custom Icon */}
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <div className={classes.iconWrapper}>
          <div className={isSuccessfull ? classes.icon : classes.iconFail}>
            {isSuccessfull ? (
              <CheckIcon style={{ color: "#30077C" }} />
            ) : (
              <CloseIcon style={{ color: "#7c0707" }} />
            )}
          </div>
        </div>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {isSuccessfull ? (
          <>
            <Typography
              style={{ fontSize: "18px", marginBottom: "20px" }}
              variant="h6"
            >
              Purchase Successful
            </Typography>
            <Typography style={{ fontSize: "14px" }} variant="body2">
              You have successfully purchased <strong>{credits}</strong>{" "}
              {creditType === "reportCredits"
                ? "swing score credits. You can now go to Swing Library to generate reports."
                : "student credits. You can now go to Students page to add credits."}
            </Typography>
            <div style={{ paddingTop: "20px" }}>
              {creditType === "reportCredits" ? (
                <a href="/swing-library" className={classes.link}>
                  Swing Library ↗
                </a>
              ) : (
                <a href="/students" className={classes.link}>
                  Students ↗
                </a>
              )}
            </div>
          </>
        ) : (
          <Typography style={{ fontSize: "14px" }} variant="body2">
            Failed to purchase credits
          </Typography>
        )}

        <Button
          onClick={onClose}
          variant="contained"
          className={classes.closeButton}
        >
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PurchaseResultDialog;
