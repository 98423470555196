import React, { Component } from "react";
import PropTypes, { bool } from "prop-types";
import { functionBaseUrl, axiosWithToken } from "../../common/firebase";
import firebase from "../../common/firebase";
import { withStyles } from "@material-ui/styles";
import {
  Grid,
  Container,
  Button,
  Avatar,
  Typography,
  CssBaseline,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  CardActions,
  Box,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { AccountNotFound } from "../account/components";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import Swal from "sweetalert2";
import AddStudent from "../students/AddStudent";
import AddStudentAdmin from "../students/AddStudentAdmin";
import withReactContent from "sweetalert2-react-content";
import NoSubscriptionDialog from "../../components/NoSubscriptionDialog";
import { ImportStudentsEnterprise } from "../students/ImportStudentsEnterprise";
import { AddCredit } from "./AddCredit";
import moment from "moment";
import { listOfSubscriptions } from "../../common/envConfig";
import Axios from "axios";
import MaterialTable from "material-table";
const MySwal = withReactContent(Swal);

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paperReceived: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "60%",
  },
  paperSent: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "60%",
  },
  inviteButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(4),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    marginTop: theme.spacing(4),
  },
  buttonLink: {
    // This is a link component surrounding a button component
    width: "100%",
  },
  linkedButton: {
    // This is a button component surrounded by a link
    margin: theme.spacing(1, 0),
  },
});

/*const RejectButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);*/

class Invites extends Component {
  constructor(props) {
    const firebaseUser = firebase.auth().currentUser;
    super(props);
    this.state = {
      uid: firebaseUser.uid,
      error: "",
      loading: true,
      role: "",
      userName: "",
      email: "",
      invitesSent: [],
      pendingInvitesSent: [],
      invitesReceived: [],
      roleCheck: "",
      coachInviteCount: 0,
      studentInviteCount: 0,
      popUpOpen: false,
      popUpStudentOpen: false,
      subData: {},
      subscriptionType: "",
      value: 0,
      modalEmail: "",
      modalMonths: 0,
      modalName: "",
      userEmail: "",
      newEmail: "",
      coachEmail: "",
      coaches: [],
      subEmail: "",
      subPeriod: 0,
      subPlan: listOfSubscriptions.PREMIUM_MONTHLY,
      partnerSubPlan: listOfSubscriptions.FORESIGHT_MONTHLY,
      subLabel: "test",
      activeSub: true,
      isStaffEnterprise: false,
      isCertified: true,
      onboardingCompleted: true,
      dialogLoading: true,
      certification: "Sportsbox 3D Golf Level 1 Certification",
      addStudentPopup: false,
      program: "",
      bootcampUser: false,
      creditPopup: false,
      permission: "internal",
      action: "add",
      //subType: 'Enterprise Master',
    };
  }

  async componentDidMount() {
    if (!this.state.uid) {
      this.setState({ error: "Please log in to view invites" });
      this.setState({ loading: false });
    } else {
      await axiosWithToken(functionBaseUrl + "/api/users/" + this.state.uid, {
        method: "get",
      })
        .then(async (response) => {
          var role = response.data.role;
          let name = response.data.firstName + " " + response.data.lastName;
          this.setState({
            userName: name,
            role: role,
            subscriptionType: response.data.subscriptionType,
            email: response.data.email,
            loading: false,
          });

          if (!response.data.subscriptionType.includes("free")) {
            this.setState({ value: 1 });
            this.subscriptionStatus();
          }
          if (
            response.data.enterpriseAccountId &&
            response.data.enterpriseAccountId
          ) {
            const entUser = await firebase
              .firestore()
              .collection("enterpriseAccounts")
              .doc(response.data.enterpriseAccountId)
              .get();
            const entData = entUser.data();
          }
          this.setState({ dialogLoading: false });

          let tempCount = [];
          await firebase
            .firestore()
            .collection("invites")
            .where("invitee", "==", response.data.email)
            .where("status", "==", "pending")
            .where("expiredIn", ">=", Date.now())
            .get()
            .then((snapshot) => {
              snapshot.forEach(async function (doc) {
                const sender = await firebase
                  .firestore()
                  .collection("users")
                  .doc(doc.data().senderId)
                  .get();
                const senderData = sender.data();
                tempCount.push({
                  ...doc.data(),
                  id: doc.id,
                  senderEmail: senderData.email,
                });
              });
              this.setState({ invitesReceived: tempCount, loading: false });
            })
            .catch((err) => {
              console.log(err);
            });

          let tempSent = [];
          let tempPendingSent = [];
          await firebase
            .firestore()
            .collection("invites")
            .where("senderId", "==", this.state.uid)
            .where("status", "!=", "rejected")
            .get()
            .then((snapshot) => {
              snapshot.forEach(function (doc) {
                tempSent.push({ ...doc.data(), id: doc.id });
                if (doc.data().status === "pending") {
                  tempPendingSent.push({ ...doc.data(), id: doc.id });
                }
              });
              this.setState({
                invitesSent: tempSent,
                pendingInvitesSent: tempPendingSent,
                loading: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });

          let tempCoaches = [];
          if (response.data.subscriptionType.includes("enterprise")) {
            const coaches = await firebase
              .firestore()
              .collection("users")
              .where(
                "enterpriseAccountId",
                "==",
                response.data.enterpriseAccountId
              )
              .get();
            coaches.forEach(function (doc) {
              tempCoaches.push({ ...doc.data(), id: doc.id });
            });
            this.setState({ coaches: tempCoaches });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
            error: "There was an error retrieving your info",
          });
        });
    }
  }

  subscriptionStatus = async () => {
    await axiosWithToken(functionBaseUrl + "/api/verifySub/" + this.state.uid, {
      method: "post",
    })
      .then((response) => {
        this.setState({
          subData: response.data.data,
        });
        if (this.state.role !== "admin" && "success" in response.data) {
          this.setState({ activeSub: response.data.success });
        }
        if (
          Object.keys(response.data.data).length > 0 ||
          this.state.role === "admin"
        ) {
          this.checkCoachInviteCount();
          this.checkStudentInviteCount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkCoachInviteCount = () => {
    let tempCount = [];
    firebase
      .firestore()
      .collection("invites")
      .where("role", "==", "Sportsbox-Instructor")
      .where("senderId", "==", this.state.uid)
      .where("status", "!=", "rejected")
      .get()
      .then((snapshot) => {
        snapshot.forEach(function (doc) {
          tempCount.push({ ...doc.data(), id: doc.id });
        });
        this.setState({ coachInviteCount: tempCount.length });
      })
      .catch((err) => {
        console.log(err);
      });
    /*axiosWithToken(functionBaseUrl+'/api/inviteCounter', {
      method: 'post',
      data: {
        role: 'Sportsbox-Instructor',
        id: this.state.uid
      }
    }).then(response => {
      this.setState({ coachInviteCount: response.data.count });
    })
    .catch(err => {
      console.log(err)
    });*/
  };

  checkStudentInviteCount = () => {
    let tempCount = [];
    firebase
      .firestore()
      .collection("invites")
      .where("role", "==", "Sportsbox-User")
      .where("senderId", "==", this.state.uid)
      .where("status", "!=", "rejected")
      .get()
      .then((snapshot) => {
        snapshot.forEach(function (doc) {
          tempCount.push({ ...doc.data(), id: doc.id });
        });
        this.setState({ studentInviteCount: tempCount.length });
      })
      .catch((err) => {
        console.log(err);
      });
    /*axiosWithToken(functionBaseUrl+'/api/inviteCounter', {
      method: 'post',
      data: {
        role: 'Sportsbox-User',
        id: this.state.uid
      }
    }).then(response => {
      this.setState({ studentInviteCount: response.data.count });
    })
    .catch(err => {
      console.log(err)
    });*/
  };

  studentInviteLeft = () => {
    let productId = this.state.subscriptionType; //this.state.subData.productId;
    if (productId.includes("pro_lite") || productId.includes("golf_lite")) {
      return 15 - this.state.studentInviteCount;
    } else if (productId.includes("plus")) {
      return 150 - this.state.studentInviteCount;
    } else if (productId.includes("premium")) {
      return "Unlimited";
    } else if (productId.includes("enterprise")) {
      return "Unlimited";
    }
  };

  studentInviteLimit = () => {
    let productId = this.state.subscriptionType; //this.state.subData.productId
    if (productId.includes("student")) {
      return 0;
    } else if (
      productId.includes("pro_lite") ||
      productId.includes("golf_lite")
    ) {
      return 15;
    } else if (productId.includes("plus")) {
      return 150;
    } else if (productId.includes("premium")) {
      return "Unlimited";
    } else if (productId.includes("enterprise")) {
      return "Unlimited";
    }
  };

  instructorInvitesLeft = () => {
    return 5 - this.state.coachInviteCount;
  };

  approveRequest = (requestId) => (event) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Accept Invite</p>',
      html: "<p>Are you sure you want to accept this invite?</p>",
      icon: "warning",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonColor: "red",
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosWithToken(functionBaseUrl + "/api/approveInvite/" + requestId, {
            method: "POST",
          })
            .then(() => {
              let invitesReceived = [...this.state.invitesReceived];
              var requestIndex = this.state.invitesReceived
                .map((invite) => invite.id)
                .indexOf(requestId);
              invitesReceived.splice(requestIndex, 1);
              this.setState({ invitesReceived: invitesReceived });
              Swal.fire({ icon: "success" });
            })
            .catch((err) => {
              Swal.fire({ icon: "error", text: err });
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  rejectRequest = (requestId) => (event) => {
    Swal.fire({
      title: '<p style="font-size:70%;">Are you sure?</p>',
      icon: "warning",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonColor: "red",
      allowOutsideClick: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosWithToken(functionBaseUrl + "/api/rejectInvite/" + requestId, {
            method: "post",
          }).then((response) => {
            let invitesReceived = [...this.state.invitesReceived];
            var requestIndex = this.state.invitesReceived
              .map((invite) => invite.id)
              .indexOf(requestId);
            invitesReceived.splice(requestIndex, 1);

            let pendingInvitesSent = [...this.state.pendingInvitesSent];
            var sentIndex = this.state.invitesSent
              .map((invite) => invite.id)
              .indexOf(requestId);
            pendingInvitesSent.splice(sentIndex, 1);
            this.setState({
              invitesReceived: invitesReceived,
              pendingInvitesSent: pendingInvitesSent,
            });

            //window.location.reload()
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  requesterName = (invite) => {
    let name = "";
    if (invite && invite.senderName) {
      name = invite.senderName;
    }
    return name;
  };

  requesterRole = (invite) => {
    let role = "";
    if (invite?.role) {
      if (invite?.role.includes("User")) {
        role = "Student";
      }
    }
    return role;
  };

  inviteValidity = (invite) => {
    let date = "";
    if (invite && invite.expiredIn) {
      date = this.dateDisplay(invite.expiredIn);
    }
    return date;
  };

  dateDisplay = (date) => {
    return moment(String(date), "x").format("MM/DD/YYYY");
  };

  closePopUp = () => {
    this.setState({ popUpOpen: false });
  };

  openPopUpStudent = () => {
    this.checkStudentInviteCount();
    if (
      this.state.role === "admin" ||
      this.state.subscriptionType.includes("enterprise") ||
      this.state.subscriptionType.includes("premium")
    ) {
      this.setState({ popUpOpen: true, roleCheck: "Sportsbox-User" });
      return false;
    }
    if (JSON.stringify(this.state.subData) === "{}") {
      Swal.fire({
        title:
          '<p style="font-size:70%;">You cannot add students with your plan!</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return false;
    }
    if (
      this.state.subscriptionType.length > 0 &&
      this.state.subscriptionType.includes("student")
    ) {
      Swal.fire({
        title:
          '<p style="font-size:70%;">You cannot add students with your plan!</p>',
        icon: "warning",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
      return false;
    }
    if (this.state.subscriptionType.includes("enterprise")) {
      this.setState({ popUpOpen: true, roleCheck: "Sportsbox-User" });
    } else if (
      Object.keys(this.state.subData).length > 0 &&
      this.state.studentInviteCount < this.studentInviteLimit()
    ) {
      this.setState({ popUpOpen: true, roleCheck: "Sportsbox-User" });
    } else {
      Swal.fire(
        '<p style="font-size:70%;">You have exceeded your Student invite limit</p>'
      );
      return false;
    }
  };

  closePopUpStudent = () => {
    this.setState({ popUpStudentOpen: false });
  };

  openPopUpStudentAdmin = () => {
    if (this.state.role === "admin") {
      this.setState({ popUpStudentOpen: true });
    }
  };

  openPopUpCoach = () => {
    this.checkCoachInviteCount();
    //console.log('coachInvCount: ' + this.state.coachInviteCount)
    if (this.state.role === "admin") {
      this.setState({ popUpOpen: true, roleCheck: "Sportsbox-Instructor" });
      return false;
    }
    this.setState({ popUpOpen: true, roleCheck: "Sportsbox-Instructor" });
    /*if (this.state.role === 'Sportsbox-Instructor' && this.state.coachInviteCount < 5) {
      this.setState({popUpOpen: true, roleCheck: 'Sportsbox-Instructor'})
    } else {
      Swal.fire('<p style="font-size:70%;">You have exceeded your Instructor invite limit</p>')
      return false
    }*/
  };

  openPopUpAddSub = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: true,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid
              item
              //sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("subEmail")}
                defaultValue={this.state.subEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="subPlan-select">BootCamp User</InputLabel>
                <Select
                  id="bootcampUser"
                  label="Bootcamp User"
                  labelId="bootcampUser-select"
                  name="bootcampUser"
                  defaultValue={this.state.bootcampUser}
                  onChange={this.handleChange("bootcampUser")}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="subPlan-select">Subscription Plan*</InputLabel>
                <Select
                  id="subPlan"
                  label="Subscription Plan"
                  labelId="subPlan-select"
                  name="subPlan"
                  defaultValue={this.state.subPlan}
                  onChange={this.handleChange("subPlan")}
                >
                  <MenuItem value={listOfSubscriptions.STUDENT_FREE}>
                    Free
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.STUDENT_LITE_MONTHLY}>
                    3D Player
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.PREMIUM_MONTHLY}>
                    3D Pro
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.PLAYER_PLUS_MONTHLY}>
                    Player Plus
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                //disabled={this.state.subPlan.includes('free')}
                label="Subscription Period"
                onChange={this.handleChange("subPeriod")}
                defaultValue={this.state.subPeriod}
                variant="outlined"
                type="Number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">month(s)</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                onFocus={(event) => {
                  event.target.select();
                }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="subPlan-select">Subscription Label*</InputLabel>
                <Select
                  id="subLabel"
                  label="Subscription Label"
                  labelId="sublabel-select"
                  name="subLabel"
                  defaultValue={this.state.subLabel}
                  onChange={this.handleChange("subLabel")}
                >
                  <MenuItem value={"test"}>Test</MenuItem>
                  <MenuItem value={"demo"}>Demo</MenuItem>
                  <MenuItem value={"partner"}>Partner</MenuItem>
                  <MenuItem value={"revenue_generating"}>
                    Revenue Generating
                  </MenuItem>
                  <MenuItem value={"staff"}>Staff</MenuItem>
                  <MenuItem value={"free_trial"}>Free Trial</MenuItem>
                  <MenuItem value={"bootcamp"}>Bootcamp</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.state.subEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.subEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.subPlan.length === 0) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please select a Subscription Plan</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        await axiosWithToken(functionBaseUrl + "/api/addSub", {
          method: "POST",
          data: {
            email: this.state.subEmail.toLowerCase().replace(/ /g, ""),
            plan: this.state.subPlan,
            months:
              this.state.subPeriod >= 0
                ? this.state.subPeriod
                : -this.state.subPeriod,
            bootcampUser: this.state.bootcampUser,
            subLabel: this.state.subLabel,
          },
        })
          .then((creds) => {
            if (creds.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + creds.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">Subscription successfully added for: ' +
                  this.state.subEmail +
                  " with " +
                  this.state.subPeriod +
                  " months of subscription</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 5000,
              }).then(() => {
                this.setState({
                  subEmail: "",
                  subPlan: listOfSubscriptions.LITE_MONTHLY,
                  subPeriod: 0,
                  bootcampUser: false,
                  subLabel: "test",
                });
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  openPopUpAddPartnerSub = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: true,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid
              item
              //sm={6}
              xs={12}
            >
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("subEmail")}
                defaultValue={this.state.subEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="partnerSubPlan-select">Plan*</InputLabel>
                <Select
                  id="partnerSubPlan"
                  label="Plan*"
                  labelId="partnerSubPlan-select"
                  name="partnerSubPlan"
                  defaultValue={this.state.partnerSubPlan}
                  onChange={this.handleChange("partnerSubPlan")}
                >
                  <MenuItem value={listOfSubscriptions.FORESIGHT_MONTHLY}>
                    Foresight
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.WORLD_WIDE_GOLF}>
                    World Wide Golf
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.LA_GOLF_STUDIO}>
                    LA Golf Studio
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Subscription Period"
                onChange={this.handleChange("subPeriod")}
                defaultValue={this.state.subPeriod}
                variant="outlined"
                type="Number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">month(s)</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                onFocus={(event) => {
                  event.target.select();
                }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="subPlan-select">Subscription Label*</InputLabel>
                <Select
                  id="subLabel"
                  label="Subscription Label"
                  labelId="sublabel-select"
                  name="subLabel"
                  defaultValue={this.state.subLabel}
                  onChange={this.handleChange("subLabel")}
                >
                  <MenuItem value={"test"}>Test</MenuItem>
                  <MenuItem value={"demo"}>Demo</MenuItem>
                  <MenuItem value={"partner"}>Partner</MenuItem>
                  <MenuItem value={"revenue_generating"}>
                    Revenue Generating
                  </MenuItem>
                  <MenuItem value={"staff"}>Staff</MenuItem>
                  <MenuItem value={"free_trial"}>Free Trial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.state.subEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.subEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.partnerSubPlan.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please select a Plan</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        await axiosWithToken(functionBaseUrl + "/api/addPartnerSub", {
          method: "POST",
          data: {
            email: this.state.subEmail.toLowerCase().replace(/ /g, ""),
            productId: this.state.partnerSubPlan,
            months:
              this.state.subPeriod >= 0
                ? this.state.subPeriod
                : -this.state.subPeriod,
            subLabel: this.state.subLabel,
          },
        })
          .then((creds) => {
            if (creds.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + creds.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">Subscription successfully added for: ' +
                  this.state.subEmail +
                  " with " +
                  this.state.subPeriod +
                  " months of subscription</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 5000,
              }).then(() => {
                this.setState({
                  subEmail: "",
                  partnerSubPlan: listOfSubscriptions.FORESIGHT_MONTHLY,
                  subPeriod: 0,
                  subLabel: "test",
                });
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  openPopUpCreateAcc = () => {
    if (this.state.role !== "admin") {
      return false;
    }
    MySwal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: true,
      showCancelButton: true,
      preConfirm: () => {
        const email = document.getElementById("userEmail").value;
        const name = document.getElementById("userName").value;

        if (!email || !name) {
          MySwal.showValidationMessage("Both Email and Name are required");
          return false; // Prevents popup from closing if validation fails
        }

        return { email, name };
      },
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid
              item
              //sm={6}
              xs={12}
            >
              <TextField
                id="userEmail"
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("subEmail")}
                defaultValue={this.state.subEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <TextField
                id="userName"
                fullWidth
                required
                label="Name"
                onChange={this.handleChange("modalName")}
                defaultValue={this.state.modalName}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="subPlan-select">Bootcamp User</InputLabel>
                <Select
                  id="bootcampUser"
                  label="Bootcamp User"
                  labelId="bootcampUser-select"
                  name="bootcampUser"
                  defaultValue={this.state.bootcampUser}
                  onChange={this.handleChange("bootcampUser")}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="subPlan-select">Subscription Plan*</InputLabel>
                <Select
                  id="subPlan"
                  label="Subscription Plan"
                  labelId="subPlan-select"
                  name="subPlan"
                  defaultValue={this.state.subPlan}
                  onChange={this.handleChange("subPlan")}
                >
                  <MenuItem value={listOfSubscriptions.STUDENT_FREE}>
                    Free
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.STUDENT_LITE_MONTHLY}>
                    3D Player
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.PREMIUM_MONTHLY}>
                    3D Pro
                  </MenuItem>
                  <MenuItem value={listOfSubscriptions.ENTERPRISE_MASTER}>
                    Enterprise Master
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Subscription Period"
                onChange={this.handleChange("subPeriod")}
                defaultValue={this.state.subPeriod}
                variant="outlined"
                type="Number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">month(s)</InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="subPlan-select">Subscription Label*</InputLabel>
                <Select
                  id="subLabel"
                  label="Subscription Label"
                  labelId="sublabel-select"
                  name="subLabel"
                  defaultValue={this.state.subLabel}
                  onChange={this.handleChange("subLabel")}
                >
                  <MenuItem value={"test"}>Test</MenuItem>
                  <MenuItem value={"demo"}>Demo</MenuItem>
                  <MenuItem value={"partner"}>Partner</MenuItem>
                  <MenuItem value={"revenue_generating"}>
                    Revenue Generating
                  </MenuItem>
                  <MenuItem value={"staff"}>Staff</MenuItem>
                  <MenuItem value={"free_trial"}>Free Trial</MenuItem>
                  <MenuItem value={"bootcamp"}>Bootcamp</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          //console.log(this.state.subEmail + ', ' + this.state.modalName + ', ' + this.state.subPlan + ', ' + this.state.subPeriod + ', ' + this.state.bootcampUser)
          await axiosWithToken(functionBaseUrl + "/api/userAuth", {
            method: "POST",
            data: {
              email: this.state.subEmail,
              password: "ilovegolf",
              name: this.state.modalName,
              plan: this.state.subPlan,
              months:
                this.state.subPeriod !== "" && this.state.subPeriod !== 0
                  ? this.state.subPeriod
                  : 0,
              bootcampUser: this.state.bootcampUser,
              subLabel: this.state.subLabel,
            },
          })
            .then((creds) => {
              if (creds.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">An account already exists with the email: ' +
                    this.state.subEmail +
                    "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">Account successfully created for: ' +
                    this.state.subEmail +
                    " with " +
                    this.state.subPeriod +
                    " months of subscription</p>",
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 3000,
                });
              }
              this.setState({
                subEmail: "",
                modalName: "",
                subPlan: "",
                subPeriod: "",
                subLabel: "test",
                bootcampUser: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  validateEmail = (email) => {
    const verify = /\S+@\S+\.\S+/;
    return verify.test(email);
  };

  addStudentToACoach = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add/Remove Student</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: true,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Coach Email"
                onChange={this.handleChange("coachEmail")}
                defaultValue={this.state.coachEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid
              item
              //sm={6}
              xs={12}
            >
              <FormControl
                variant="outlined"
                fullWidth
                //margin="dense"
              >
                <InputLabel id="action-select">Action</InputLabel>
                <Select
                  id="action"
                  label="Action"
                  labelId="action-select"
                  name="action"
                  defaultValue={this.state.action}
                  onChange={this.handleChange("action")}
                >
                  <MenuItem value={"add"}>Add</MenuItem>
                  <MenuItem value={"remove"}>Remove</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.coachEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter Coach Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.coachEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid Coach Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.action === "add") {
          await axiosWithToken(functionBaseUrl + "/api/userCoach", {
            method: "POST",
            data: {
              userEmail: this.state.userEmail.toLowerCase().replace(/ /g, ""),
              coachEmail: this.state.coachEmail.toLowerCase().replace(/ /g, ""),
            },
          })
            .then((response) => {
              if (response.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' + response.data.message + "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' + response.data.message + "</p>",
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 2000,
                });
              }
              this.setState({ userEmail: "", coachEmail: "", action: "add" });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          await axiosWithToken(functionBaseUrl + "/api/removeStudentByEmail", {
            method: "DELETE",
            data: {
              studentEmail: this.state.userEmail.toLowerCase().replace(/ /g, ""),
              coachEmail: this.state.coachEmail.toLowerCase().replace(/ /g, ""),
            },
          })
            .then((response) => {
              if (response.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' + response.data.message + "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' + response.data.message + "</p>",
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 2000,
                });
              }
              this.setState({ userEmail: "", coachEmail: "", action: "add" });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      return false;
    });
  };

  openPopUpPermission = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add User Permissions</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="subPlan-select">Permission*</InputLabel>
                <Select
                  id="permission"
                  label="permission"
                  labelId="permission-select"
                  name="permission"
                  defaultValue={this.state.permission}
                  onChange={this.handleChange("permission")}
                >
                  <MenuItem value={""}>None</MenuItem>
                  <MenuItem value={"internal"}>Internal</MenuItem>
                  <MenuItem value={"external"}>External</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        await axiosWithToken(functionBaseUrl + "/api/permission", {
          method: "POST",
          data: {
            email: this.state.userEmail,
            permission: this.state.permission,
          },
        })
          .then((res) => {
            if (res.status === 201) {
              Swal.fire({
                title: '<p style="font-size:70%;">' + res.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                customClass: {
                  container: "my-swal",
                },
              });
            } else {
              Swal.fire({
                icon: "success",
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 3000,
                customClass: {
                  container: "my-swal",
                },
              }).then(() => {
                this.setState({
                  userEmail: "",
                  permission: "internal",
                });
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpCertification = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add Certification</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="subPlan-select">Certification*</InputLabel>
                <Select
                  id="certification"
                  label="Certification"
                  labelId="certification-select"
                  name="certification"
                  defaultValue={this.state.certification}
                  onChange={this.handleChange("certification")}
                >
                  <MenuItem value={"Sportsbox 3D Golf Level 1 Certification"}>
                    Sportsbox 3D Golf Level 1
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.certification.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please select a certificate</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        axiosWithToken(functionBaseUrl + "/api/addCertification", {
          method: "POST",
          data: {
            email: this.state.userEmail,
            certification: this.state.certification,
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpOnboarding = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add Onboarding Completed</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        axiosWithToken(functionBaseUrl + "/api/addOnboarding", {
          method: "POST",
          data: {
            email: this.state.userEmail,
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpUpdateEmail = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Update Email Address</p>',
      allowOutsideClick: false,
      confirmButtonText: "Update",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      customClass: {
        container: "my-swal",
      },
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Old Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="New Email"
                onChange={this.handleChange("newEmail")}
                defaultValue={this.state.newEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.newEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter New Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.newEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid New Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        const userResponse = await axiosWithToken(
          `${functionBaseUrl}/api/searchUserByEmail`,
          {
            method: "POST",
            data: {
              email: this.state.userEmail.toLowerCase().replaceAll(" ", ""),
            },
          }
        );
        if (userResponse?.data?.length === 0) {
          Swal.fire(
            '<p style="font-size:70%;">No user found with the email: <b>' +
              this.state.userEmail +
              "</b></p>"
          );
          return;
        }

        await axiosWithToken(functionBaseUrl + "/api/userEmail", {
          method: "PATCH",
          data: {
            id: userResponse?.data[0]?.id,
            email: this.state.newEmail.toLowerCase().replaceAll(" ", ""),
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "", newEmail: "" });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: '<p style="font-size:70%;">' + err + "</p>",
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          });
      }
      return false;
    });
  };

  openPopUpVerifyEmail = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Verify Email Address</p>',
      allowOutsideClick: false,
      confirmButtonText: "Verify",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        await axiosWithToken(functionBaseUrl + "/api/emailVerf", {
          method: "POST",
          data: {
            email: this.state.userEmail.toLowerCase().replaceAll(" ", ""),
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: '<p style="font-size:70%;">' + err + "</p>",
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          });
      }
      return false;
    });
  };

  openPopUpVerifyStudentCoach = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Verify Student Coach Connection</p>',
      allowOutsideClick: false,
      confirmButtonText: "Verify",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      customClass: {
        container: "my-swal",
      },
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="Coach Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Student Email"
                onChange={this.handleChange("newEmail")}
                defaultValue={this.state.newEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          html: '<div><img width="10%" src="images/loading.gif" alt="Loading" /></div>',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          customClass: {
            container: "my-swal",
          },
        });

        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
            customClass: {
              container: "my-swal",
            },
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
            customClass: {
              container: "my-swal",
            },
          });
          return false;
        }
        if (this.state.newEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter Student Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
            customClass: {
              container: "my-swal",
            },
          });
          return false;
        }
        if (!this.validateEmail(this.state.newEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid Student Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
            customClass: {
              container: "my-swal",
            },
          });
          return false;
        }

        await axiosWithToken(`${functionBaseUrl}/api/verifyStudentCoach`, {
          method: "post",
          data: {
            coachEmail: this.state.userEmail.toLowerCase().replaceAll(" ", ""),
            studentEmail: this.state.newEmail.toLowerCase().replaceAll(" ", ""),
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">Coach Email: <b>' +
                  this.state.userEmail +
                  "</b></p><br/>" +
                  '<p style="font-size:70%;">Student Email: <b>' +
                  this.state.newEmail +
                  "</b></p><br/><br />" +
                  '<p style="font-size:70%;">Coach Account Exists: ' +
                  (response.data?.coachAccountExists
                    ? "<span style='color: green;'>&#x2713;</span>"
                    : "<span style='color: red;'>&#x2715;</span>") +
                  "</p><br/>" +
                  '<p style="font-size:70%;">Student Account Exists: ' +
                  (response.data?.studentAccountExists
                    ? "<span style='color: green;'>&#x2713;</span>"
                    : "<span style='color: red;'>&#x2715;</span>") +
                  "</p><br/>" +
                  '<p style="font-size:70%;">Coach is connected to Student: ' +
                  (response.data?.coachIsConnectedToStudent
                    ? "<span style='color: green;'>&#x2713;</span>"
                    : "<span style='color: red;'>&#x2715;</span>") +
                  "</p><br/>" +
                  '<p style="font-size:70%;">Student is connected to Coach: ' +
                  (response.data?.studentIsConnectedToCoach
                    ? "<span style='color: green;'>&#x2713;</span>"
                    : "<span style='color: red;'>&#x2715;</span>") +
                  "</p><br/>" +
                  '<p style="font-size:70%;">Invite Status: <b>' +
                  response.data?.inviteStatus +
                  "</b></p><br/>" +
                  '<p style="font-size:70%;">Messages: \n<b>' +
                  response.data?.messages?.toString().replaceAll(",", "\n\n") +
                  "</b></p><br/>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                customClass: {
                  container: "my-swal",
                },
              });
              return;
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">Coach Email: <b>' +
                  this.state.userEmail +
                  "</b></p><br/>" +
                  '<p style="font-size:70%;">Student Email: <b>' +
                  this.state.newEmail +
                  "</b></p><br/><br />" +
                  '<p style="font-size:70%;">Coach Account Exists: ' +
                  (response.data?.coachAccountExists
                    ? "<span style='color: green;'>&#x2713;</span>"
                    : "<span style='color: red;'>&#x2715;</span>") +
                  "</p><br/>" +
                  '<p style="font-size:70%;">Student Account Exists: ' +
                  (response.data?.studentAccountExists
                    ? "<span style='color: green;'>&#x2713;</span>"
                    : "<span style='color: red;'>&#x2715;</span>") +
                  "</p><br/>" +
                  '<p style="font-size:70%;">Coach is connected to Student: ' +
                  (response.data?.coachIsConnectedToStudent
                    ? "<span style='color: green;'>&#x2713;</span>"
                    : "<span style='color: red;'>&#x2715;</span>") +
                  "</p><br/>" +
                  '<p style="font-size:70%;">Student is connected to Coach: ' +
                  (response.data?.studentIsConnectedToCoach
                    ? "<span style='color: green;'>&#x2713;</span>"
                    : "<span style='color: red;'>&#x2715;</span>") +
                  "</p><br/>" +
                  '<p style="font-size:70%;">Invite Status: <b>' +
                  response.data?.inviteStatus +
                  "</b></p><br/>" +
                  '<p style="font-size:70%;">Messages: \n<b>' +
                  response.data?.messages?.toString().replaceAll(",", "\n\n") +
                  "</b></p><br/>",
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                customClass: {
                  container: "my-swal",
                },
              });
            }
            this.setState({ userEmail: "", newEmail: "" });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: '<p style="font-size:70%;">' + err + "</p>",
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          });
      }
      return false;
    });
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  openPopUpImport = () => {
    this.setState({ addStudentPopup: true });
  };

  closePopUpImport = () => {
    this.setState({ addStudentPopup: false });
  };

  openPopUpReferralRock = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add Referral Rock Member</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="program-select">Program*</InputLabel>
                <Select
                  id="program"
                  label="Program"
                  labelId="program-select"
                  name="program"
                  defaultValue={this.state.program}
                  onChange={this.handleChange("program")}
                >
                  <MenuItem value={"8dc10e86-17ef-4c2a-88cb-b62e38d59408"}>
                    Ambassador Program (20%)
                  </MenuItem>
                  <MenuItem value={"dd71ff73-54bf-473f-a8e7-614e7ec0c22b"}>
                    Ambassador Program (25%)
                  </MenuItem>
                  <MenuItem value={"a6383ca8-f975-4861-afd9-c07c3efb51e8"}>
                    Training Aid Partners (30%)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (this.state.program.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please select a Program</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        await axiosWithToken(functionBaseUrl + "/api/member", {
          method: "POST",
          data: {
            email: this.state.userEmail,
            programId: this.state.program,
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">Account created successfully!</p>',
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "", program: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpClearDevices = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Clear Devices List</p>',
      allowOutsideClick: false,
      confirmButtonText: "Clear",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        await axiosWithToken(functionBaseUrl + "/api/userDevices", {
          method: "DELETE",
          data: {
            userEmail: this.state.userEmail.toLowerCase().replace(/ /g, ""),
          },
        })
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">Devices list successfully cleared!</p>',
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpAddUserToDevExc = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add User to Device Exceptions List</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        await axiosWithToken(
          functionBaseUrl + "/api/mobileAppSettings/addUser",
          {
            method: "PATCH",
            data: {
              email: this.state.userEmail.toLowerCase().replace(/ /g, ""),
            },
          }
        )
          .then((response) => {
            if (response.status === 201) {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">' + response.data.message + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                title:
                  '<p style="font-size:70%;">User successfully added to the device exceptions list!</p>',
                icon: "success",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 2000,
              });
            }
            this.setState({ userEmail: "" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    });
  };

  openPopUpBootcamp = () => {
    MySwal.fire({
      title: '<p style="font-size:80%;">Add Existing User to Bootcamp</p>',
      allowOutsideClick: false,
      confirmButtonText: "Add",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (this.state.userEmail.length === 0) {
          Swal.fire({
            title: '<p style="font-size:70%;">Please enter User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }
        if (!this.validateEmail(this.state.userEmail)) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">Please enter a valid User Email</p>',
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        }

        const user = await firebase
          .firestore()
          .collection("users")
          .where("email", "==", this.state.userEmail)
          .get();
        if (user.size === 0) {
          Swal.fire({
            title:
              '<p style="font-size:70%;">No user exists with the email: ' +
              this.state.userEmail +
              "</p>",
            icon: "warning",
            confirmButtonText: "Ok",
            allowOutsideClick: true,
          });
          return false;
        } else {
          user.forEach(async (doc) => {
            let data = doc.data();
            if (data) {
              await axiosWithToken(functionBaseUrl + "/api/bootcampHubUser", {
                method: "POST",
                data: {
                  email: this.state.userEmail.toLowerCase().replace(/ /g, ""),
                  firstName: data.firstName,
                  lastName: data.lastName,
                },
              })
                .then((response) => {
                  if (response.status === 201) {
                    Swal.fire({
                      title:
                        '<p style="font-size:70%;">' +
                        response.data.message +
                        "</p>",
                      icon: "warning",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            await firebase
              .firestore()
              .collection("users")
              .doc(doc.id)
              .update({ bootcampUser: true });
            const sub = await firebase
              .firestore()
              .collection("subscriptions")
              .where("userId", "==", doc.id)
              .get();
            sub.forEach(async (subDoc) => {
              await firebase
                .firestore()
                .collection("subscriptions")
                .doc(subDoc.id)
                .update({ bootcampUser: true });
            });
          });
          Swal.fire({
            title:
              '<p style="font-size:70%;">User successfully added to bootcamp!</p>',
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            timer: 2000,
          });
        }
      }
      this.setState({ userEmail: "" });
      return false;
    });
  };

  openPopUpRemoveSub = () => {
    MySwal.fire({
      title: `<p style="font-size:80%;">Cancel User's Subscription</p>`,
      allowOutsideClick: false,
      confirmButtonText: "Confirm",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </div>
      ),
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          if (this.state.userEmail.length === 0) {
            Swal.fire({
              title: `<p style="font-size:70%;">Please enter User's Email</p>`,
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: true,
            });
            return false;
          }
          if (!this.validateEmail(this.state.userEmail)) {
            Swal.fire({
              title:
                '<p style="font-size:70%;">Please enter a valid User Email</p>',
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: true,
            });
            return false;
          }

          await axiosWithToken(functionBaseUrl + "/api/cancelSub", {
            method: "POST",
            data: {
              email: this.state.userEmail.toLowerCase().replace(/ /g, ""),
            },
          })
            .then((response) => {
              if (response.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' +
                    response.data.message +
                    "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 3000,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">Subscription ended successfully</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 2000,
                });
              }
              this.setState({ userEmail: "" });
              return false;
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                title: '<p style="font-size:70%;">' + err + "</p>",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                timer: 3000,
              });
              return false;
            });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: '<p style="font-size:70%;">' + err + "</p>",
          icon: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          timer: 3000,
        });
        return false;
      });
  };

  getPlanDescription = (productId) => {
    if (productId) {
      productId = productId.replaceAll("sportsbox_", "");
      productId = productId.replaceAll("development_", "");
      productId = productId.replaceAll("_", " ");
      var splitStr = productId.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    } else {
      return "";
    }
  };

  openPopUpFreeTrialTag = () => {
    MySwal.fire({
      title: `<p style="font-size:80%;">Enroll User To Hubspot Free Trial Flow</p>`,
      allowOutsideClick: false,
      confirmButtonText: "Confirm",
      showCloseButton: true,
      allowOutsideClick: false,
      showCancelButton: true,
      html: (
        <div style={{ maxWidth: "90%", margin: "0 auto", marginTop: "5%" }}>
          <Grid container spacing={2}>
            <Grid item sm xs={12}>
              <TextField
                fullWidth
                required
                label="User Email"
                onChange={this.handleChange("userEmail")}
                defaultValue={this.state.userEmail}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            {/*<Grid
              item
              xs={12}
            >
              <FormControl              
                variant="outlined"
                fullWidth
              >
                <InputLabel id="sub-select">Subscription Type*</InputLabel>
                <Select
                  id="sub"
                  label="Sub"
                  labelId="sub-select"
                  name="sub"
                  defaultValue={this.state.subType}
                  onChange={this.handleChange('subType')}
                >
                  <MenuItem value={'Free'}>Free</MenuItem>
                  <MenuItem value={'3D Pro'}>3D Pro</MenuItem>
                  <MenuItem value={'Enterprise Master'}>Enterprise Master</MenuItem>
                  <MenuItem value={'Enterprise Coach'}>Enterprise Coach</MenuItem>
                  <MenuItem value={'Staff Coach'}>Staff Coach</MenuItem>
                  <MenuItem value={'Student Lite'}>Student Lite</MenuItem>
                </Select>
              </FormControl>
            </Grid>*/}
          </Grid>
        </div>
      ),
    })
      .then(async (response) => {
        if (response.isConfirmed) {
          if (this.state.userEmail.length === 0) {
            Swal.fire({
              title: `<p style="font-size:70%;">Please enter User's Email</p>`,
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: true,
            });
            return false;
          }
          if (!this.validateEmail(this.state.userEmail)) {
            Swal.fire({
              title:
                '<p style="font-size:70%;">Please enter a valid User Email</p>',
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: true,
            });
            return false;
          }

          const user = await axiosWithToken(
            functionBaseUrl + "/api/searchUserByEmail",
            {
              method: "POST",
              data: {
                email: this.state.userEmail.toLowerCase().replace(/ /g, ""),
              },
            }
          ).catch((err) => {
            console.log(err);
          });

          if (user.data[0]) {
            await axiosWithToken(functionBaseUrl + "/api/hubUser", {
              method: "POST",
              data: {
                email: this.state.userEmail.toLowerCase().replace(/ /g, ""),
                firstName: user.data[0].firstName,
                lastName: user.data[0].lastName,
                subscriptionType: await this.getPlanDescription(
                  user.data[0].subscriptionType
                ),
                freeTrialUsed: "Yes",
              },
            })
              .then((response) => {
                if (response.status === 201) {
                  Swal.fire({
                    title:
                      '<p style="font-size:70%;">' +
                      response.data.message +
                      "</p>",
                    icon: "warning",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    timer: 3000,
                  });
                } else {
                  Swal.fire({
                    icon: "success",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    timer: 2000,
                  });
                }
                this.setState({ userEmail: "" });
                return false;
              })
              .catch((err) => {
                console.log(err);
                Swal.fire({
                  title: '<p style="font-size:70%;">' + err + "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 3000,
                });
                return false;
              });
          } else {
            Swal.fire({
              title:
                '<p style="font-size:70%;">No user found with the email: ' +
                this.state.userEmail +
                "</p>",
              icon: "warning",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              timer: 3000,
            });
            return false;
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          title: '<p style="font-size:70%;">' + err + "</p>",
          icon: "warning",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          timer: 3000,
        });
        return false;
      });
  };

  openPopUpCredit = () => {
    this.setState({ creditPopup: true });
  };

  closePopUpCredit = () => {
    this.setState({ creditPopup: false });
  };

  handleDeleteButton = () => {
    Swal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      html: `
        <label for="email">Email:</label>
        <input id="email" class="swal2-input">
        `,
      focusConfirm: false,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
      showCancelButton: true,
      customClass: {
        container: "my-swal",
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
          axiosWithToken(
            functionBaseUrl +
              "/api/userByEmail/" +
              document
                .getElementById("email")
                .value.toLowerCase()
                .replace(/ /g, ""),
            {
              method: "DELETE",
            }
          )
            .then((response) => {
              if (response.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' +
                    response.data.message +
                    "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">Account successfully deleted</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  timer: 2000,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAnyUserPassChange = () => {
    Swal.fire({
      title: '<p style="font-size:80%;">Enter User Details</p>',
      html: `
        <label for="emailPass">Email:</label>
        <input id="emailPass" class="swal2-input">
        `,
      focusConfirm: false,
      confirmButtonText: "Change",
      confirmButtonColor: "green",
      showCancelButton: true,
      customClass: {
        container: "my-swal",
      },
    })
      .then((result) => {
        let userEmail = document
          .getElementById("emailPass")
          .value.toLowerCase()
          .replace(/ /g, "");
        if (result.isConfirmed) {
          axiosWithToken(functionBaseUrl + "/api/anyUserPass", {
            method: "PATCH",
            data: {
              email: userEmail,
              password: "ilovegolf",
            },
          })
            .then((response) => {
              if (response.status === 201) {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' +
                    response.data.message +
                    "</p>",
                  icon: "warning",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                });
              } else {
                Swal.fire({
                  title:
                    '<p style="font-size:70%;">' +
                    userEmail +
                    ' password successfully changed to "<b>ilovegolf<b>"</p>',
                  icon: "success",
                  confirmButtonText: "Ok",
                  allowOutsideClick: true,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <Container component="main" className={this.props.classes.paper}>
        <CssBaseline />
        <div className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
            {this.state.value === 0 ? <CallReceivedIcon /> : <CallMadeIcon />}
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
            style={{ marginBottom: "10px" }}
          >
            INVITES
          </Typography>
          <Box
            style={{ marginBottom: "2%" }}
            //sx={{ width: "50%", bgcolor: "background.paper" }}
          >
            <Tabs
              value={this.state.value}
              onChange={this.handleChange("value")}
              centered
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                label="Pending"
                onClick={() => this.setState({ value: 0 })}
              />
              {(this.state.subscriptionType.length > 0 &&
                !this.state.subscriptionType.includes("student")) ||
              this.state.role === "admin" ? (
                <Tab label="Sent" onClick={() => this.setState({ value: 1 })} />
              ) : (
                <div></div>
              )}
            </Tabs>
          </Box>
          {this.state.value === 0 ? (
            <div>
              <Card style={{ minWidth: "50%" }}>
                <CardContent>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Total Invites Received:</b>&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                    >
                      {this.state.invitesReceived.length}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
              <div className={this.props.classes.root}>
                {this.state.invitesReceived.length === 0
                  ? "There are no invites"
                  : this.state.invitesReceived.map((invite) => (
                      <Card style={{ minWidth: "50%" }} key={invite.id}>
                        <CardHeader
                          title={
                            this.requesterName(invite) +
                            " (" +
                            invite.senderEmail +
                            ")"
                          }
                          titleTypographyProps={{
                            variant: "h5",
                            color: "primary",
                          }}
                        />
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginTop: "-10px",
                          }}
                        >
                          <i>
                            {"is inviting your for the role of " +
                              this.requesterRole(invite)}
                          </i>
                        </Typography>
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginBottom: "10px",
                          }}
                        >
                          <b>Valid Till: </b>
                          {" " + this.inviteValidity(invite)}
                        </Typography>
                        <Divider />
                        <CardContent>
                          <Grid container justify="space-between">
                            <Button
                              style={{ margin: "0 auto", display: "flex" }}
                              variant="contained"
                              color="primary"
                              justify="center"
                              onClick={this.approveRequest(invite.id)}
                            >
                              Accept
                            </Button>

                            <Button
                              style={{ margin: "0 auto", display: "flex" }}
                              variant="contained"
                              color="secondary"
                              justify="center"
                              onClick={this.rejectRequest(invite.id)}
                            >
                              Reject
                            </Button>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
              </div>
            </div>
          ) : (
            <div>
              <Card style={{ minWidth: "50%" }}>
                <CardContent>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Student Invites Sent:</b>&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                    >
                      {this.state.studentInviteCount}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                    >
                      <b>Student Invites Left:</b>&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                      style={{ marginBottom: "10px" }}
                    >
                      {this.studentInviteLeft()}
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid container justify="space-between">
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      style={{ marginTop: "10px" }}
                    >
                      <b>Coach Invites Sent:</b>&nbsp;
                    </Typography>
                    <Typography
                      inline
                      component="h6"
                      variant="h5"
                      justify="center"
                      align="right"
                      style={{ marginTop: "10px" }}
                    >
                      {this.state.coachInviteCount}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
              <div className={this.props.classes.inviteButtons}>
                {/*<Button
                  variant="contained"
                  color="primary"
                  className={this.props.classes.linkedButton}
                  onClick={this.openPopUpStudent}
                >
                  ADD A NEW STUDENT
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={this.props.classes.linkedButton}
                  onClick={this.openPopUpCoach}
                >
                  INVITE A COACH
                </Button>*/}
                {this.state.role === "admin" && (
                  <>
                    {/*<Button
                      variant="contained"
                      color="primary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpStudentAdmin}
                    >
                      ADD A NEW STUDENT (ADMIN)
                    </Button>*/}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpCreateAcc}
                    >
                      CREATE A NEW ACCOUNT
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.addStudentToACoach}
                    >
                      ADD/REMOVE STUDENT
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpAddSub}
                    >
                      ADD SUBSCRIPTION TO A USER
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpAddPartnerSub}
                    >
                      ADD PARTNER SUBSCRIPTION TO A USER
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpPermission}
                    >
                      ADD USER PERMISSIONS
                    </Button>
                    {/*<Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpCertification}
                    >
                      ADD CERTIFICATION
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpOnboarding}
                    >
                      ADD ONBOARDING COMPLETED
                    </Button>*/}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpImport}
                    >
                      IMPORT STUDENT ACCOUNTS FOR COACH
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpReferralRock}
                    >
                      ADD REFERRAL ROCK MEMBER
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpClearDevices}
                    >
                      CLEAR USER'S DEVICES LIST
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpAddUserToDevExc}
                    >
                      ADD USER TO DEVICE EXCEPTIONS LIST
                    </Button>
                    {/*<Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpBootcamp}
                    >
                      ADD EXISTING USER TO BOOTCAMP
                    </Button>*/}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpRemoveSub}
                    >
                      CANCEL USER'S SUBSCRIPTION
                    </Button>
                    {/*<Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpFreeTrialTag}
                    >
                      ENROLL USER TO HUBSPOT FREE TRIAL FLOW
                    </Button>*/}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpUpdateEmail}
                    >
                      UPDATE EMAIL ADDRESS
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpVerifyEmail}
                    >
                      VERIFY EMAIL ADDRESS
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpCredit}
                    >
                      ADD/REMOVE CREDITS
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.linkedButton}
                      onClick={this.openPopUpVerifyStudentCoach}
                    >
                      VERIFY STUDENT COACH CONNECTION
                    </Button>
                    <Button
                      className={this.props.classes.linkedButton}
                      variant="contained"
                      color="secondary"
                      style={{ backgroundColor: "green" }}
                      onClick={this.handleAnyUserPassChange}
                    >
                      Change Any Account's Password
                    </Button>
                    <Button
                      className={this.props.classes.linkedButton}
                      variant="contained"
                      color="secondary"
                      style={{ backgroundColor: "red" }}
                      onClick={this.handleDeleteButton}
                    >
                      Delete Any Account
                    </Button>
                    <ImportStudentsEnterprise
                      open={this.state.addStudentPopup}
                      close={this.closePopUpImport}
                      uid={this.state.uid}
                      name={this.state.userName}
                      admin={true}
                    />
                  </>
                )}
              </div>
              <AddStudent
                open={this.state.popUpOpen}
                onClose={this.closePopUp}
                name={this.state.userName}
                uid={this.state.uid}
                roleCheck={this.state.roleCheck}
                subscriptionType={this.state.subscriptionType}
                coaches={this.state.coaches}
              />
              <AddStudentAdmin
                open={this.state.popUpStudentOpen}
                onClose={this.closePopUpStudent}
                name={this.state.userName}
                uid={this.state.uid}
                roleCheck="Sportsbox-User"
              />
              <AddCredit
                open={this.state.creditPopup}
                close={this.closePopUpCredit}
              />
              <div className={this.props.classes.root}>
                {this.state.pendingInvitesSent.length === 0
                  ? "There are no invites"
                  : this.state.pendingInvitesSent.map((invite) => (
                      <Card style={{ maxWidth: "100%" }} key={invite.id}>
                        <CardHeader
                          title={
                            invite.inviteeName + " (" + invite.invitee + ")"
                          }
                          titleTypographyProps={{
                            variant: "h5",
                            color: "primary",
                          }}
                        />
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginTop: "-10px",
                          }}
                        >
                          <i>
                            {"has been invited to be added as your " +
                              this.requesterRole(invite)}
                          </i>
                        </Typography>
                        <Typography
                          component="h6"
                          variant="h6"
                          justify="center"
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginBottom: "10px",
                          }}
                        >
                          <b>Valid Till: </b>
                          {" " + this.inviteValidity(invite)}
                        </Typography>
                        <Divider />
                        <CardContent>
                          <Button
                            style={{ margin: "0 auto", display: "flex" }}
                            variant="contained"
                            color="secondary"
                            onClick={this.rejectRequest(invite.id)}
                          >
                            Cancel
                          </Button>
                        </CardContent>
                      </Card>
                    ))}
              </div>
            </div>
          )}
        </div>
        {/* Error screen*/}
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style={{
            display: this.state.error && !this.state.loading ? "block" : "none",
          }}
        >
          <AccountNotFound error={{ error: this.state.error }} />
        </Grid>
        <NoSubscriptionDialog
          activeSub={this.state.activeSub}
          isStaffEnterprise={this.state.isStaffEnterprise}
          isCertified={this.state.isCertified}
          onboardingCompleted={this.state.onboardingCompleted}
          role={this.state.role}
          loading={this.state.dialogLoading}
          subscriptionType={this.state.subscriptionType}
        />
      </Container>
    );
  }
}

Invites.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withStyles(useStyles)(Invites);
