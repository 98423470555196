import React, { useState, useCallback } from "react";
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Swal from "sweetalert2";
import axios from "axios";
import * as admin from "firebase/app";
import "firebase/firestore";
import { functionBaseUrl } from "../../common/firebase";

const useStyles = makeStyles((theme) => ({
  // Enhanced container style for visibility on dark backgrounds
  container: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(4),
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  // Basic form styling
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  // Spacing for submit button
  submit: {
    marginTop: theme.spacing(3),
  },
  // Spacing for field groups
  fieldGroup: {
    marginTop: theme.spacing(2),
  },
  // Caption style for the title
  ctaCaption: {
    fontFamily: "Anton",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "80px",
    lineHeight: "90%",
    textTransform: "uppercase",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(7.5),
    color: "#0B115A",
  },
}));

// Utility to generate height options (from 4'6" to 7'0")
const generateHeightOptions = () => {
  const options = [];
  for (let inches = 54; inches <= 84; inches++) {
    const feet = Math.floor(inches / 12);
    const remainingInches = inches % 12;
    const heightStr = `${feet}'${remainingInches}"`;
    options.push({ value: heightStr, label: heightStr });
  }
  return options;
};

const heightOptions = generateHeightOptions();

const RoastMySwing = () => {
  const classes = useStyles();

  const [videoFile, setVideoFile] = useState(null);
  const [handedness, setHandedness] = useState("right");
  const [gender, setGender] = useState("male");
  const [height, setHeight] = useState("5'10\""); // e.g., "5'7\""
  const [swingGoals, setSwingGoals] = useState("");
  const [videoName, setVideoName] = useState("");
  const [club, setClub] = useState("D");

  const handleVideoChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const convertHeightToInches = (height) => {
    const [feet, inches] = height.split("'");
    return parseInt(feet) * 12 + parseInt(inches.replace('"', ""));
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      // Show a loading indicator
      Swal.fire({
        title: "Uploading video...",
        html: '<div><img width="10%" src="/images/loading.gif" alt="Loading" /></div>',
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const tempUrl = await uploadVideo();
      await analyzeVideo(tempUrl);
    },
    [videoFile, handedness, gender, height, swingGoals]
  );

  const uploadVideo = async () => {
    const tempName = `${gender}_${handedness}_${Date.now()}`;
    setVideoName(tempName);
    // Create a reference to the storage
    var storageRef = admin.storage().ref();
    // Create a reference to the file you are about to create
    var fileRef = storageRef.child("/roast_my_swing/videos/" + tempName + ".mp4");
    // upload you blob into the storage
    await fileRef.put(videoFile);
    // get the download URL
    return await fileRef.getDownloadURL();
  };

  const analyzeVideo = async (videoUrl) => {
    Swal.fire({
      title: 'Analyzing...',
      html: '<div><img width="10%" src="images/loading.gif" alt="Loading" /></div>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });

    let host = '';
    if (window.location.href.includes('localhost')) {
      host = 'http';
    } else {
      host = 'https';
    }

    await axios(host + '://gpu.sportsbox.ai:40030/web_inference/', {
      method: 'POST',
      data: {
        videoUrl: videoUrl,
        fps: 120,
        //intrinsic: [[1371.8, 0, 539.4], [0, 1366.5, 973.2], [0, 0, 1]],
        model_2d_name: "Sportsbox_Golf_Pose2D_FO_DTL_38_235K_20231208",
        model_3d_name: "Sportsbox_Golf_Pose3D_FO_35_2M_20231004",
        club_type: club,
        height: convertHeightToInches(height),
        hand: handedness.toLowerCase(),
      },
    })
      .then(async response => {
        let totalFrames = response.data.json2d.frames.length;
        let totalScore = 0;
        for (const frame of response.data.json2d.frames) {
          totalScore += frame.score;
        }
        let averageScore = ((totalScore / totalFrames) * 100).toFixed(2);
        console.log('Average Score: ' + averageScore);
        console.log('Swing Confidence Score: ' + response.data?.jsonai?.swingConfidenceScore);
        if (averageScore > 80) {
          const jsonAI = {
            swing: {
              start: response.data.jsonai?.swing?.start,
              end: response.data.jsonai?.swing?.end,
              position: response.data.jsonai?.swing?.position,
            },
            indicators: response.data.jsonai?.indicators
          }
          await getSwingReport(jsonAI);
        } else {
          Swal.fire({
            title: '<p style="font-size:70%;">Swing and a miss error because confidence score was ' + averageScore + '%</p>',
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: true
          })
        }
      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          title: '<p style="font-size:70%;">No swing detected</p>',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: true
        })
      });
  }

  const getSwingReport = async (jsonData) => {
    Swal.fire({
      title: 'Generating report...',
      html: '<div><img width="10%" src="images/loading.gif" alt="Loading" /></div>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    });

    await axios(
      functionBaseUrl + "/api/swing-report",
      //"http://localhost:8080/sportsbox-development/us-central1/api/swing-report",
      {
        method: "POST",
        data: {
          jsonData: jsonData,
          gender: gender.toUpperCase(),
          height: height,
          golfClub: club,
        },
      }
    )
      .then((response) => {
        Swal.fire({
          title: "Swing Feedback",
          html: `<p>Your overall score: ${response?.data?.overallScore}</p>
          <br />
          <p>Your club speed: ${response?.data?.speedPercentile?.speedIndicatorValue}</p>
          <br />
          <p>Your speed score: ${response?.data?.speedPercentile?.speedPercentileScore}</p>
          <br />
          <p>Your efficiency score: ${response?.data?.efficiencyScore?.efficiencyScore}</p>`,
          icon: "info",
          confirmButtonColor: "#3f51b5",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
          .then(() => {
            window.location.reload();
          });
      })
      .catch((err) => {
        console.log(err.response?.data);
        Swal.fire({
          title: "Error",
          text: "An error occurred while generating the report: " + err.response?.data?.message,
          icon: "error",
          confirmButtonColor: "#3f51b5",
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
        return null;
      });
  }

  return (
    <Container component="main" maxWidth="sm" className={classes.container}>
      <CssBaseline />
      <Typography
        className={classes.ctaCaption}
        component="h1"
        variant="h4"
        align="center"
      >
        Roast My Swing
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        {/* Video Upload */}
        <input
          accept="video/*"
          style={{ display: "none" }}
          id="video-upload"
          type="file"
          onChange={handleVideoChange}
        />
        <label htmlFor="video-upload">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            fullWidth
          >
            {videoFile ? "Video Selected" : "Upload Video"}
          </Button>
        </label>

        {/* Radio Groups (Handedness, Gender, and Club Type) */}
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.fieldGroup}
        >
          <Grid item>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Handedness</FormLabel>
              <RadioGroup
                row
                aria-label="handedness"
                name="handedness"
                value={handedness}
                onChange={(e) => setHandedness(e.target.value)}
              >
                <FormControlLabel
                  value="left"
                  control={<Radio />}
                  label="Left"
                />
                <FormControlLabel
                  value="right"
                  control={<Radio />}
                  label="Right"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Club</FormLabel>
              <RadioGroup
                row
                aria-label="club"
                name="club"
                value={club}
                onChange={(e) => setClub(e.target.value)}
              >
                <FormControlLabel
                  value="D"
                  control={<Radio />}
                  label="Driver"
                />
                <FormControlLabel
                  value="7I"
                  control={<Radio />}
                  label="Iron"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {/* Height Dropdown */}
        <FormControl fullWidth className={classes.fieldGroup}>
          <InputLabel id="height-label">Height*</InputLabel>
          <Select
            labelId="height-label"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            required
          >
            {heightOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Swing Goals Input */}
        {/*<TextField
          label="Swing Goals"
          fullWidth
          margin="normal"
          value={swingGoals}
          onChange={(e) => setSwingGoals(e.target.value)}
        />*/}

        {/* Analyze Swing Button */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary" // now using primary instead of secondary
          className={classes.submit}
          disabled={!videoFile || !handedness || !gender || !height}
        >
          Analyze Swing
        </Button>
      </form>
    </Container>
  );
};

export default RoastMySwing;
