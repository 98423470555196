import React, { useEffect, useState, useRef } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import s from "./SwingTable.module.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { SelectV2 } from "../../../../components/redesign-components/SelectV2/SelectV2";
import { Tooltip } from "@material-ui/core";

const SortableHeader = ({
  header,
  handleSort,
  renderSortArrow,
  columnIndex,
  onHover,
}) => {
  const {
    setNodeRef,
    transform,
    transition,
    attributes,
    listeners,
    isDragging,
  } = useSortable({
    id: header.id,
    disabled: columnIndex === 0,
  });

  const style = {
    opacity: isDragging ? 0.8 : 1,
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging ? 10 : 5,
    fontSize: "14px",
  };

  return (
    <th ref={setNodeRef} style={style} className={`${s.stickyHeader} `}>
      {!header.isPlaceholder && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: header.id === "videoPath" ? "start" : "end",
            flexDirection: "column",
          }}
        >
          {columnIndex !== 0 && (
            <span {...attributes} {...listeners} style={{ cursor: "grab" }}>
              <DragIndicatorIcon />
            </span>
          )}
          <span
            style={{
              overflow: "hidden",
              wordBreak: "break-word",
              whiteSpace: "normal",
              maxWidth: "75px",
            }}
            onClick={() => handleSort(header.column.columnDef.accessorKey)}
            className={s.sortHeaderContainer}
            onMouseEnter={() => onHover(header.column.columnDef.accessorKey)}
            onMouseLeave={() => onHover(null)}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {renderSortArrow(header.column.columnDef.accessorKey)}
          </span>
        </div>
      )}
    </th>
  );
};

export const SwingTable2 = ({
  data,
  analysisAggregates,
  currentPage,
  onPageChange,
  pageNumber,
  onSort,
  sort,
  columns,
  rowSelection,
  setRowSelection,
  selectedTableData,
  rowsPerPage,
  onRowsPerPageChange,
  filterValueIndicators,
  isTourAveragesActive,
  indicatorStdAvg,
}) => {
  const [columnOrder, setColumnOrder] = useState(columns.map((col) => col.id));
  const [potentialSortArrow, setPotentialSortArrow] = useState(null);

  const tableContainerRef = useRef(null);
  const isTableContainerDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleTableContainerMouseDown = (e) => {
    isTableContainerDragging.current = true;
    startX.current = e.pageX - tableContainerRef.current.offsetLeft;
    scrollLeft.current = tableContainerRef.current.scrollLeft;
  };

  const handleTableContainerMouseMove = (e) => {
    if (!isTableContainerDragging.current) return; // Only move if mouse is pressed
    e.preventDefault(); // Prevent default action (like text selection)
    const x = e.pageX - tableContainerRef.current.offsetLeft;
    const walk = (x - startX.current) * 1.5; // Adjust the scroll speed
    tableContainerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleTableContainerMouseUp = () => {
    isTableContainerDragging.current = false;
  };

  useEffect(() => {
    setColumnOrder(columns.map((col) => col.id));
  }, [columns]);

  const table = useReactTable({
    state: {
      rowSelection,
      columnOrder,
    },
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    columnResizeMode: "onChange",
  });

  const handleSort = (columnAccessorKey) => {
    let currentSortField = sort?.sort;
    const currentSortDirection = sort?.order;

    if (sort.sort === "videoCreated") {
      currentSortField = "videoPath";
    }

    const newDirection =
      currentSortField === columnAccessorKey
        ? currentSortDirection === "asc"
          ? "desc"
          : "asc"
        : "desc";
    onSort(columnAccessorKey, newDirection);
  };

  const renderPageNumber = () => {
    const totalVisiblePages = 3; // Number of surrounding pages (current, before, after)
    const arr = [];

    for (let i = 1; i <= pageNumber; i++) {
      arr.push(i);
    }

    return (
      <div style={{ display: "flex", gap: "10px" }}>
        {/* First page always visible */}
        {pageNumber > 1 && (
          <div
            key={1}
            onClick={() => onPageChange(0)}
            className={`${
              currentPage === 0 ? s.activePaginationButton : s.paginationButton
            }`}
          >
            1
          </div>
        )}

        {/* Ellipsis before the middle section if current page is beyond totalVisiblePages */}
        {currentPage >= totalVisiblePages && (
          <div style={{ cursor: "auto" }} className={s.paginationButton}>
            ...
          </div>
        )}

        {/* Middle section with previous, current, and next pages */}
        {arr
          .slice(
            Math.max(1, currentPage - 1), // Start from previous page (or 2 if too low)
            Math.min(pageNumber - 1, currentPage + 2) // Up to next page (or total pages - 1 if too high)
          )
          .map((el) => (
            <div
              key={el}
              onClick={() => onPageChange(el - 1)}
              className={`${
                currentPage === el - 1
                  ? s.activePaginationButton
                  : s.paginationButton
              }`}
            >
              {el}
            </div>
          ))}

        {/* Ellipsis after the middle section if current page is before the last visible pages */}
        {currentPage <= pageNumber - totalVisiblePages - 1 && (
          <div style={{ cursor: "auto" }} className={s.paginationButton}>
            ...
          </div>
        )}

        {/* Last page always visible */}
        <div
          key={pageNumber}
          onClick={() => onPageChange(pageNumber - 1)}
          className={`${
            currentPage === pageNumber - 1
              ? s.activePaginationButton
              : s.paginationButton
          }`}
        >
          {pageNumber}
        </div>
      </div>
    );
  };

  const renderSortArrow = (accessorKey) => {
    let currentSortField = potentialSortArrow ? potentialSortArrow : sort?.sort;

    if (sort.sort === "videoCreated" || potentialSortArrow === "videoCreated") {
      currentSortField = "videoPath";
    }

    if (currentSortField === accessorKey) {
      if (potentialSortArrow) {
        if (sort.order === "desc") {
          return <ArrowDropUpIcon />;
        } else {
          return <ArrowDropDownIcon />;
        }
      } else {
        if (sort.order === "desc") {
          return <ArrowDropDownIcon />;
        } else {
          return <ArrowDropUpIcon />;
        }
      }
    } else {
      return "";
    }

    return currentSortField === accessorKey ? (
      sort.order === "desc" ? (
        <ArrowDropDownIcon />
      ) : (
        <ArrowDropUpIcon />
      )
    ) : (
      ""
    );
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (
      active.id !== over?.id &&
      active.id !== columnOrder[0] &&
      over?.id !== columnOrder[0]
    ) {
      setColumnOrder((prevOrder) => {
        const oldIndex = prevOrder.indexOf(active.id);
        const newIndex = prevOrder.indexOf(over.id);
        return arrayMove(prevOrder, oldIndex, newIndex);
      });
    }
  };

  const convertUnit = (unit, value) => {
    switch (unit.toLowerCase()) {
      case "degrees":
      case "degrees/second":
        return value; // Assuming value is already in degrees or degrees/sec
      case "inches":
        return value / 25.4; // Convert mm to inches (1 inch = 25.4 mm)
      case "mph":
        return value * 0.002237; // Convert mm/s to mph (1 mm/s = 0.002237 mph)
      case "m/s/s":
        return value / 1000; // Convert mm/s^2 to m/s^2
      case "g":
      case "gs":
        return value / 9806.65; // Convert mm/s^2 to g (1 g ≈ 9806.65 mm/s^2)
      case "seconds":
        return value;
      case "ms":
        return value * 1000;
      case "percent":
        return value * 100; // Convert ratio to percentage
      case "ratio":
        return value; // Assuming ratio is unitless
      default:
        return value; // Return the original value if no conversion is needed
    }
  };

  const renderUnitType = (unit, value) => {
    if (value === "n/a") return value;
    const parsedUnit = unit ? unit.toLowerCase() : "";
    const parsedValue = value ? +value : 0;

    // Convert the value based on the unit
    const convertedValue = convertUnit(parsedUnit, parsedValue);

    switch (parsedUnit) {
      case "degrees":
        return `${convertedValue.toFixed()}°`;
      case "inches":
        return `${convertedValue.toFixed(1)}"`;
      case "mm":
        return `${convertedValue.toFixed()} mm`;
      case "seconds":
        return `${convertedValue.toFixed(2)} sec`;
      case "ratio":
        return `${convertedValue.toFixed(2)} ratio`;
      case "ms":
        return `${convertedValue.toFixed()} ms`;
      case "percent":
        return `${convertedValue.toFixed()}%`;
      case "mph":
        return `${convertedValue.toFixed(1)} mph`;
      case "m/s/s":
        return `${convertedValue.toFixed(2)} m/s²`;
      case "g":
      case "gs":
        return `${convertedValue.toFixed(1)} g`;
      case "degrees/second":
        return `${convertedValue.toFixed()} d/s`;
      case "order":
        return `${convertedValue.toFixed()}`;
      default:
        return convertedValue.toFixed();
    }
  };

  const renderAvgAndStd = (type) => {
    const orderedColumns = columnOrder
      .map((colId) => {
        const col = columns.find((col) => col.id === colId);
        const indicator = filterValueIndicators.find((col) => col.id === colId);
        if (indicator) {
          return { ...col, unit: indicator.unit };
        }
        return col;
      })
      .filter(Boolean);
    if (type === "avg") {
      return orderedColumns
        .slice(5)
        .map((el) => {
          const res = selectedTableData.reduce(
            (acc, el2) => {
              if (
                el2?.analysis?.indicators &&
                el.id in el2?.analysis?.indicators
              ) {
                acc.validSum += el2?.analysis?.indicators[el.id];
                acc.validLength += 1;
              }

              return acc;
            },
            { validLength: 0, validSum: 0 }
          );

          return {
            analysisData:
              el.id in analysisAggregates && analysisAggregates[el.id]?.avg
                ? analysisAggregates[el.id]?.avg
                : 0,
            selectedData: res.validLength
              ? (res.validSum / res.validLength).toFixed(2)
              : 0,
            unit: el.unit,
          };
        })
        .map((el) => {
          return (
            <td className={`${s.tableStdAndAvg} ${s.tableAvg}`}>
              <div className={s.stdAvgItemConatiner}>
                <div>
                  {renderUnitType(
                    el.unit,
                    el.analysisData ? el.analysisData : "n/a"
                  )}
                </div>
                {selectedTableData.length ? (
                  <div className={s.stdAvgBorderedItem}>
                    {renderUnitType(
                      el.unit,
                      el.selectedData ? el.selectedData : "n/a"
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </td>
          );
        });
    } else if (type === "std") {
      return orderedColumns
        .slice(5)
        .map((el) => {
          const { validSum, validLength } = selectedTableData.reduce(
            (acc, el2) => {
              if (
                el2?.analysis?.indicators &&
                el.id in el2?.analysis?.indicators
              ) {
                acc.validSum += el2?.analysis?.indicators[el.id];
                acc.validLength += 1;
              }
              return acc;
            },
            { validLength: 0, validSum: 0 }
          );
          const mean = validLength ? validSum / validLength : 0;
          const variance = selectedTableData.reduce((acc, el2) => {
            if (
              el2?.analysis?.indicators &&
              el.id in el2?.analysis?.indicators
            ) {
              const value = el2?.analysis?.indicators[el.id];
              acc += Math.pow(value - mean, 2); // (value - mean)^2
            }
            return acc;
          }, 0);

          const stdDev = validLength
            ? Math.sqrt(variance / validLength).toFixed(2)
            : 0;

          return {
            analysisData:
              el.id in analysisAggregates && analysisAggregates[el.id]?.std
                ? analysisAggregates[el.id]?.std
                : 0,
            selectedData: stdDev,
            unit: el.unit,
          };
        })
        .map((el) => {
          return (
            <td className={`${s.tableStdAndAvg} ${s.tableStd}`}>
              <div className={s.stdAvgItemConatiner}>
                <div>
                  {renderUnitType(
                    el.unit,
                    el.analysisData ? el.analysisData : "n/a"
                  )}
                </div>
                {selectedTableData.length ? (
                  <div className={s.stdAvgBorderedItem}>
                    {renderUnitType(
                      el.unit,
                      el.selectedData ? el.selectedData : "n/a"
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </td>
          );
        });
    }
  };

  // const renderAvgAndStdV2 = (type) => {
  //   const orderedColumns = columnOrder
  //     .map((colId) => {
  //       const col = columns.find((col) => col.id === colId);
  //       const indicator = filterValueIndicators.find((col) => col.id === colId);
  //       if (indicator) {
  //         return { ...col, unit: indicator.unit };
  //       }
  //       return col;
  //     })
  //     .filter(Boolean);

  //   function getColor(deviationFromTourAvg, stdDeviation) {
  //     const absDeviation = Math.abs(deviationFromTourAvg);
  //     const absStdDev = Math.abs(stdDeviation);

  //     if (absDeviation < absStdDev) {
  //       return "#027A48";
  //     } else if (absDeviation >= absStdDev && absDeviation < 1.5 * absStdDev) {
  //       return "#FF692E";
  //     } else {
  //       return "#D92D20";
  //     }
  //   }

  //   if (indicatorStdAvg) {
  //     if (type === "MI") {
  //       return orderedColumns
  //         .slice(5)
  //         .map((el) => {
  //           const res = indicatorStdAvg["MI_FACEON"].find((el2) => {
  //             if (
  //               el2.indicatorId.toUpperCase().trim() ===
  //               el.id.toUpperCase().trim()
  //             )
  //               return true;
  //             return false;
  //           });

  //           const reductionFn = (acc, el2) => {
  //             if (
  //               el2?.analysis?.indicators &&
  //               el.id in el2?.analysis?.indicators &&
  //               el2?.userData?.golfClub !== "D"
  //             ) {
  //               acc.validSum += el2?.analysis?.indicators[el.id];
  //               acc.validLength += 1;
  //             }

  //             return acc;
  //           };

  //           const tourAvg = (() => {
  //             if (selectedTableData.length) {
  //               return selectedTableData.reduce(reductionFn, {
  //                 validLength: 0,
  //                 validSum: 0,
  //               });
  //             } else {
  //               return data.reduce(reductionFn, {
  //                 validLength: 0,
  //                 validSum: 0,
  //               });
  //             }
  //           })();

  //           return {
  //             std: res?.indicatorStDeviation ?? null,
  //             avg: res?.indicatorRange ?? null,
  //             tourAvg: tourAvg.validLength
  //               ? tourAvg.validSum / tourAvg.validLength
  //               : null,
  //             unit: el.unit,
  //           };
  //         })
  //         .map((el) => {
  //           return (
  //             <td className={`${s.tableStdAndAvg} ${s.tableAvg}`}>
  //               <div className={s.stdAvgItemConatiner}>
  //                 <div
  //                   style={{
  //                     color:
  //                       isTourAveragesActive && el.avg && el.tourAvg && el.std
  //                         ? getColor(el.tourAvg, el.std)
  //                         : "black",
  //                   }}
  //                 >
  //                   {renderUnitType(el.unit, el.avg - el.tourAvg ?? "n/a")}
  //                 </div>
  //                 <div>{renderUnitType(el.unit, el.std ?? "n/a")}</div>

  //                 {isTourAveragesActive ? (
  //                   <div>{renderUnitType(el.unit, el.tourAvg ?? "n/a")}</div>
  //                 ) : (
  //                   ""
  //                 )}
  //               </div>
  //             </td>
  //           );
  //         });
  //     } else if (type === "D") {
  //       return orderedColumns
  //         .slice(5)
  //         .map((el) => {
  //           const res = indicatorStdAvg["D_FACEON"].find((el2) => {
  //             if (
  //               el2.indicatorId.toUpperCase().trim() ===
  //               el.id.toUpperCase().trim()
  //             )
  //               return true;
  //             return false;
  //           });

  //           const reductionFn = (acc, el2) => {
  //             if (
  //               el2?.analysis?.indicators &&
  //               el.id in el2?.analysis?.indicators &&
  //               el2?.userData?.golfClub === "D"
  //             ) {
  //               acc.validSum += el2?.analysis?.indicators[el.id];
  //               acc.validLength += 1;
  //             }

  //             return acc;
  //           };

  //           const tourAvg = (() => {
  //             if (selectedTableData.length) {
  //               return selectedTableData.reduce(reductionFn, {
  //                 validLength: 0,
  //                 validSum: 0,
  //               });
  //             } else {
  //               return data.reduce(reductionFn, {
  //                 validLength: 0,
  //                 validSum: 0,
  //               });
  //             }
  //           })();

  //           return {
  //             std: res?.indicatorStDeviation ?? null,
  //             avg: res?.indicatorRange ?? null,
  //             tourAvg: tourAvg.validLength
  //               ? tourAvg.validSum / tourAvg.validLength
  //               : null,
  //             unit: el.unit,
  //           };
  //         })
  //         .map((el) => {
  //           return (
  //             <td className={`${s.tableStdAndAvg} ${s.tableStd}`}>
  //               <div className={s.stdAvgItemConatiner}>
  //                 <div
  //                   style={{
  //                     color:
  //                       isTourAveragesActive && el.avg && el.tourAvg && el.std
  //                         ? getColor(el.avg - el.tourAvg, el.std)
  //                         : "black",
  //                   }}
  //                 >
  //                   {renderUnitType(el.unit, el.avg ?? "n/a")}
  //                 </div>
  //                 <div>{renderUnitType(el.unit, el.std ?? "n/a")}</div>

  //                 {isTourAveragesActive ? (
  //                   <div>{renderUnitType(el.unit, el.tourAvg ?? "n/a")}</div>
  //                 ) : (
  //                   ""
  //                 )}
  //               </div>
  //             </td>
  //           );
  //         });
  //     }
  //   }
  // };

  const renderAvgAndStdV3 = (type) => {
    const orderedColumns = columnOrder
      .map((colId) => {
        const col = columns.find((col) => col.id === colId);
        const indicator = filterValueIndicators.find((col) => col.id === colId);
        if (indicator) {
          return { ...col, unit: indicator.unit };
        }
        return col;
      })
      .filter(Boolean);

    function getColor(deviationFromTourAvg, stdDeviation) {
      const absDeviation = Math.abs(deviationFromTourAvg);
      const absStdDev = Math.abs(stdDeviation);

      if (absDeviation < absStdDev) {
        return "#027A48";
      } else if (absDeviation >= absStdDev && absDeviation < 1.5 * absStdDev) {
        return "#FF692E";
      } else {
        return "#D92D20";
      }
    }

    if (indicatorStdAvg) {
      if (type === "MI") {
        return orderedColumns
          .slice(5)
          .map((el) => {
            const res =
              indicatorStdAvg?.tourAverageRow &&
              "iron" in indicatorStdAvg?.tourAverageRow &&
              el.id in indicatorStdAvg?.tourAverageRow["iron"]
                ? indicatorStdAvg?.tourAverageRow["iron"][el.id]
                : {
                    calculatedValue: null,
                    indicatorRange: null,
                    indicatorStDeviation: null,
                  };

            return {
              std: res?.indicatorStDeviation ?? null,
              avg: res?.indicatorRange ?? null,
              tourAvg: res?.calculatedValue ?? null,
              unit: el.unit,
            };
          })
          .map((el) => {
            return (
              <td className={`${s.tableStdAndAvg} ${s.tableAvg} ${s.textLeft}`}>
                <div className={s.stdAvgItemConatiner}>
                  <div
                    style={{
                      color:
                        isTourAveragesActive && el.avg && el.tourAvg && el.std
                          ? getColor(el.tourAvg, el.std)
                          : "black",
                    }}
                  >
                    {renderUnitType(el.unit, el.avg - el.tourAvg ?? "n/a")}
                  </div>
                  <div>{renderUnitType(el.unit, el.std ?? "n/a")}</div>

                  {isTourAveragesActive ? (
                    <div>{renderUnitType(el.unit, el.tourAvg ?? "n/a")}</div>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            );
          });
      } else if (type === "D") {
        return orderedColumns
          .slice(5)
          .map((el) => {
            const res =
              indicatorStdAvg?.tourAverageRow &&
              "driver" in indicatorStdAvg?.tourAverageRow &&
              el.id in indicatorStdAvg?.tourAverageRow["driver"]
                ? indicatorStdAvg?.tourAverageRow["driver"][el.id]
                : {
                    calculatedValue: null,
                    indicatorRange: null,
                    indicatorStDeviation: null,
                  };

            return {
              std: res?.indicatorStDeviation ?? null,
              avg: res?.indicatorRange ?? null,
              tourAvg: res?.calculatedValue ?? null,
              unit: el.unit,
            };
          })
          .map((el) => {
            return (
              <td className={`${s.tableStdAndAvg} ${s.tableStd} ${s.textLeft}`}>
                <div className={s.stdAvgItemConatiner}>
                  <div
                    style={{
                      color:
                        isTourAveragesActive && el.avg && el.tourAvg && el.std
                          ? getColor(el.tourAvg, el.std)
                          : "black",
                    }}
                  >
                    {renderUnitType(el.unit, el.avg - el.tourAvg ?? "n/a")}
                  </div>
                  <div>{renderUnitType(el.unit, el.std ?? "n/a")}</div>

                  {isTourAveragesActive ? (
                    <div>{renderUnitType(el.unit, el.tourAvg ?? "n/a")}</div>
                  ) : (
                    ""
                  )}
                </div>
              </td>
            );
          });
      }
    }
  };

  const changePage = (dir) => {
    if (dir === "forward") {
      if (pageNumber > currentPage + 1) {
        onPageChange(currentPage + 1);
      }
    } else {
      if (currentPage + 1 > 1) {
        onPageChange(currentPage - 1);
      }
    }
  };

  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const avgRowRef = useRef(null);
  const [avgRowHeight, setAvgRowHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.getBoundingClientRect().height);
    }
    if (avgRowRef.current) {
      setAvgRowHeight(avgRowRef.current.getBoundingClientRect().height);
    }
  }, [
    headerRef.current,
    avgRowRef.current,
    rowSelection,
    isTourAveragesActive,
    columns,
    columnOrder,
  ]);

  const stdRowTop = headerHeight + avgRowHeight;

  return (
    <div>
      <div
        style={{ overflow: "auto", width: "100%", height: "1000px" }}
        ref={tableContainerRef}
      >
        <table style={{ tableLayout: "fixed" }}>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <thead ref={headerRef}>
              <SortableContext
                items={table
                  .getHeaderGroups()[0]
                  .headers.map((header) => header.id)}
                strategy={verticalListSortingStrategy}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                      <SortableHeader
                        key={header.id}
                        header={header}
                        handleSort={handleSort}
                        renderSortArrow={renderSortArrow}
                        columnIndex={index}
                        onHover={setPotentialSortArrow}
                      />
                    ))}
                  </tr>
                ))}
              </SortableContext>
            </thead>
          </DndContext>
          {/* <tbody>
            <tr
              ref={avgRowRef}
              className={`${s.stickyAvg}`}
              style={{ top: `${headerHeight - 2}px` }}
            >
              <td className={`${s.tableStdAndAvg} ${s.tableAvg}`}></td>
              <td className={`${s.tableStdAndAvg} ${s.tableAvg}`} colSpan={4}>
                <div className={s.stdAvgContainer}>
                  <div>Averages</div>

                  <div className={s.stdAvgSelected}>
                    {Object.entries(rowSelection).length
                      ? ` (${Object.entries(rowSelection).length}) Selected`
                      : ""}
                  </div>
                </div>
              </td>
              {renderAvgAndStd("avg")}
            </tr>
            <tr
              className={`${s.stickyStd}`}
              style={{ top: `${stdRowTop - 3}px` }}
            >
              <td className={`${s.tableStdAndAvg} ${s.tableStd}`}></td>
              <td className={`${s.tableStdAndAvg} ${s.tableStd}`} colSpan={4}>
                <div className={s.stdAvgContainer}>
                  <div>Std. Deviation</div>
                  <div className={s.stdAvgSelected}>
                    {Object.entries(rowSelection).length
                      ? ` (${Object.entries(rowSelection).length}) Selected`
                      : ""}
                  </div>
                </div>
              </td>
              {renderAvgAndStd("std")}
            </tr>
          </tbody> */}

          <tbody style={{ fontSize: "14px" }}>
            <tr
              ref={avgRowRef}
              className={`${s.stickyAvg}`}
              style={{ top: `${headerHeight - 2}px` }}
            >
              <td className={`${s.tableStdAndAvg} ${s.tableAvg}`}></td>
              <td className={`${s.tableStdAndAvg} ${s.tableAvg}`} colSpan={4}>
                <div className={s.stdAvgContainer}>
                  <div>Average (Irons)</div>
                  <div>
                    Z Score
                    <span className={s.tooltipContainer}>
                      <img
                        src="/images/icons/help_icon.png"
                        alt="Help"
                        className={s.tooltipIcon}
                      />
                      <span className={s.tooltipText}>Standard deviation is the definition of how many deviations exist between swings</span>
                    </span>
                  </div>
                  {isTourAveragesActive && <div>Deviation from Tour Avg</div>}
                </div>
              </td>
              {renderAvgAndStdV3("MI")}
            </tr>
            <tr
              className={`${s.stickyStd}`}
              style={{ top: `${stdRowTop - 3}px` }}
            >
              <td className={`${s.tableStdAndAvg} ${s.tableStd}`}></td>
              <td className={`${s.tableStdAndAvg} ${s.tableStd}`} colSpan={4}>
                <div className={s.stdAvgContainer}>
                  <div>Average (Driver)</div>
                  <div>
                    Z Score
                    <span className={s.tooltipContainer}>
                      <img
                        src="/images/icons/help_icon.png"
                        alt="Help"
                        className={s.tooltipIcon}
                      />
                      <span className={s.tooltipText}>Standard deviation is the definition of how many deviations exist between swings</span>
                    </span>
                  </div>
                  {isTourAveragesActive && <div>Deviation from Tour Avg</div>}
                </div>
              </td>
              {renderAvgAndStdV3("D")}
            </tr>
          </tbody>

          <tbody
            onMouseDown={handleTableContainerMouseDown}
            onMouseMove={handleTableContainerMouseMove}
            onMouseLeave={handleTableContainerMouseUp} // In case the mouse leaves the table while dragging
            onMouseUp={handleTableContainerMouseUp}
            style={{ fontSize: "14px" }}
          >
            {table.getRowModel().rows.map((row) => (
              <tr style={{ height: "75px" }} key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td style={{ textAlign: "end" }}>
                    {cell.column.id === cell.column.id.toUpperCase() &&
                    !cell.getContext().getValue() ? (
                      <Tooltip
                        title="This swing hasn’t been analyzed yet. Open this 
                          swing in the Sportsbox app to analyze its 3D data"
                      >
                        <p>n/a</p>
                      </Tooltip>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={s.paginationContainer}>
        {pageNumber > 0 ? (
          <div
            onClick={() => changePage("back")}
            className={s.paginationButton}
          >
            <ArrowBackIcon fontSize="small" /> Previous
          </div>
        ) : (
          <div></div>
        )}

        {data.length > 0 && (
          <SelectV2
            label="Items per page"
            name="rowsPerPage"
            onChange={(_, value) => onRowsPerPageChange(value)}
            options={[
              { text: 10, value: 10 },
              { text: 20, value: 20 },
              { text: 50, value: 50 },
              { text: 100, value: 100 },
              { text: 250, value: 250 },
            ]}
            value={rowsPerPage}
            width="100px"
          />
        )}

        {renderPageNumber()}

        <div style={{ width: "100px" }}> </div>

        {pageNumber > 1 ? (
          <div
            onClick={() => changePage("forward")}
            className={s.paginationButton}
          >
            Next <ArrowForwardIcon fontSize="small" />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
