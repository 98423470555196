import React, { useState } from 'react';

import { Grid, CircularProgress, Container } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment';
import Swal from 'sweetalert2';

import { StripeSubscription } from '../../components/stripeSubscription';
import { StripePayment } from '../../components/stripePayment';
import firebase, { functionBaseUrl, axiosWithToken } from '../../common/firebase';
import { getEndDate, getPlan, getPlanPrice } from '../../common/plans';
import axios from 'axios';
import { listOfSubscriptions } from '../../common/envConfig';

function PlansPaymentComponent({ plan, bootcampUser, freeTrial, email, uid, finish, back, subData, fullName, addOn }) {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(bootcampUser ? "400" : addOn ? getPlanPrice(plan) + (plan.includes('monthly') ? 40 : 400) : getPlanPrice(plan));

  const handlePrice = (value) => {
    setPrice(value);
  }

  const createSubscription = async (data) => {
    setLoading(true);

    let referralCode = window.location.href;
    if (referralCode.includes("REFERRALCODE=")) {
      referralCode = referralCode.split("REFERRALCODE=")[1];
    } else {
      referralCode = "";
    }

    if (bootcampUser) {
      const userSnap = await axiosWithToken(functionBaseUrl + '/api/users/' + uid, {
        method: 'GET'
      })
        .catch(err => {
          console.log(err)
        });
      const user = userSnap?.data;

      await axiosWithToken(functionBaseUrl + "/api/users/" + uid, {
        method: 'PATCH',
        data: {
          userInfo: {
            bootcampUser: bootcampUser
          },
        },
      })
        .catch(err => {
          console.log(err)
        });

      const stripeSub = await axios(functionBaseUrl + '/api/stripeCreateSubscription', {
        method: 'POST',
        data: {
          email: email,
          productId: plan,
          freeTrial: 61,
        }
      })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
            icon: 'error',
            customClass : { 
              container : 'my-swal' 
            },
          });
        });

      await axiosWithToken(functionBaseUrl + '/api/bootcampTeachableUser', {
        method: 'POST',
        data: {
          email: email,
          uid: uid,
          name: user ? user.firstName + ' ' + user.lastName : "3D Golfer"
        }
      })
        .catch(err => {
          console.log(err)
          Swal.fire({
            title: '<p style="font-size:70%;">There was an error creating your Teachble account, please reach out to customer support for assistance.</p>',
            icon: 'error',
            customClass : { 
              container : 'my-swal' 
            },
          });
        });

      await axiosWithToken(functionBaseUrl + '/api/bootcampEmail', {
        method: 'POST',
        data: {
          uid: uid,
          email: email,
          bootcampUser: bootcampUser,
        }
      })
        .catch(err => {
          console.log(err);
        });

      await axiosWithToken(functionBaseUrl + '/api/pauseStripeSubByID/' + stripeSub.data.subscription.id, {
        method: 'POST',
      })
        .catch(err => {
          console.log(err);
        });

      await axiosWithToken(functionBaseUrl + '/api/subscription', {
        method: 'POST',
        data: {
          userId: uid,
          plan: plan,
          planDescription: '',
          planType: plan?.includes('monthly') ? 'Monthly' : 'Annually',
          transactionId: stripeSub.data.subscription.id,
          payload: '',
          packageId: '',
          orderId: '',
          amount: data?.amount > 1500 ? data?.amount / 100 : data?.amount,
          startDate: String(Date.now()),
          endDate: bootcampUser ? String(moment().add(2, "M").valueOf()) : getEndDate(plan),
          lastUpdated: String(Date.now()),
          autoRenewal: bootcampUser ? false : true,
          platform: 'Stripe',
          productId: plan,
          bootcampUser: bootcampUser,
          referralCode: referralCode,
        }
      }).then(() => {
        finish();
        setLoading(false);
      })
        .catch(err => {
          console.log(err)
          Swal.fire({
            title: '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
            icon: 'error'
          });
        })
    } else {
      await axiosWithToken(functionBaseUrl + '/api/subscription', {
        method: 'POST',
        data: {
          userId: uid,
          plan: plan,
          planDescription: '',
          planType: plan?.includes('monthly') ? 'Monthly' : 'Annually',
          transactionId: data.id,
          payload: '',
          packageId: '',
          orderId: '',
          amount: data.amount / 100,
          startDate: String(Date.now()),
          endDate: bootcampUser ? String(moment().add(2, "M").valueOf()) : getEndDate(plan),
          lastUpdated: String(Date.now()),
          autoRenewal: bootcampUser ? false : true,
          platform: 'Stripe',
          productId: plan,
          bootcampUser: bootcampUser
        }
      }).then(async() => {
        if (addOn) {
          const partnerInput = {
            userId: uid,
            plan:  plan?.includes('monthly') ? "Foresight Monthly" : "Foresight Annually",
            planType:  plan?.includes('monthly') ? "Monthly" : "Annually",
            transactionId: data.id,
            amount: plan?.includes('monthly') ? "40" : "400",
            startDate: String(Date.now()),
            endDate: plan?.includes('monthly') ? String(moment().add(1, "M").valueOf()) : String(moment().add(1, "y").valueOf()),
            lastUpdated: String(Date.now()),
            autoRenewal: true,
            platform: "Stripe",
            productId: plan?.includes('monthly') ? listOfSubscriptions.FORESIGHT_MONTHLY : listOfSubscriptions.FORESIGHT_ANNUALLY,
            userName: fullName,
            userEmail: email,
            promoCode: "",
            isAddOn: false,
          }
      
          await axiosWithToken(functionBaseUrl + '/api/partnerSub', {
            method: 'POST',
            data: partnerInput,
          })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong! Please try again.",
                customClass : { 
                  container : 'my-swal' 
                },
              });
            });
        }

        await Swal.fire({
          icon: "success",
          showConfirmButton: false,
          showCloseButton: false,
          timer: 2000,
          customClass : { 
            container : 'my-swal' 
          },
        })
        finish();
        setLoading(false);
        return;
      })
        .catch(err => {
          console.log(err)
          Swal.fire({
            title: '<p style="font-size:70%;">There was an error processing your subscription, please try again.</p>',
            icon: 'error'
          });
        })
    }
  }

  return (<>
    <Grid container>
      <Grid
        item
        xs={12}
        md={2}
      />
      <Grid
        item
        xs={12}
        md={8}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            className="plans-step-back"
          >
            <a href="#" onClick={() => back()}>
              <ArrowBack />
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            className="plans-summary-caption"
          >
            {bootcampUser? "Bootcamp" : getPlan(plan) + (addOn ? " + Foresight" : "")}
          </Grid>
          {!bootcampUser && freeTrial && !plan.includes('free') && <Grid
            item
            xs={12}
            className="plans-payment-description"
          >
            You will not be charged until your free tial ends on {moment().add(14, "d").format('MM/DD/YYYY')}.
          </Grid>}
          {!freeTrial && <Grid
            item
            xs={12}
            className="plans-payment-description"
          >
            Your card will be charged ${price}.
          </Grid>}
          <Grid
            item
            xs={12}
            className="plans-payment-caption"
            style={{ marginBottom: "5%" }}
          >
            Billing information
          </Grid>
          {loading ? (<Container style={{ textAlign: 'center', paddingTop: '50px' }}><CircularProgress /></Container>) : bootcampUser ?
            (<Grid
              item
              xs={12}
              style={{ marginTop: "5%" }}
            >
              <StripePayment
                amount={400}
                handleResult={createSubscription}
                email={email}
                buttonText="Subscribe"
                hideSavedCard={true}
              />
            </Grid>)
            :
            (<Grid
              item
              xs={12}
              style={{ marginTop: "5%" }}
            >
              <StripeSubscription
                email={email}
                productId={plan}
                handleResult={createSubscription}
                freeTrial={(plan.includes('free') || subData?.freeTrialUsed || !freeTrial) ? 0 : 14}
                showDetails={false}
                fullName={fullName}
                handlePrice={handlePrice}
                addOn={addOn}
              />
            </Grid>)
          }
        </Grid>
      </Grid>
    </Grid>
  </>);
}

export default PlansPaymentComponent;