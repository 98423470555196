import React, { useCallback, useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ReportPageTemplate } from "./components/ReportPageTemplate";
import { ReportIndicatorCardGroup } from "./components/ReportIndicatorCardGroup";
import { ReportIndicatorCard } from "./components/ReportIndicatorCard";

import { ReportMainPage } from "./components/ReportMainPage";
import admin, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../../common/firebase";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { ReportIndicatorCardSequencing } from "./components/ReportIndicatorCardSequencing";
import Swal from "sweetalert2";
import { ReportGenerationDialog } from "./components/ReportGenerationDialog";
import { ReportChartPage } from "./components/ReportChartPage";

export const GeneratePdfButton = ({
  videoIds,
  userName,
  userId,
  golfClub,
  gender,
  height,
  credits,
  verificationUsers,
  verificationClubs,
  verificationCameraAngles,
  verificationAnalysed,
  isTest = false,
}) => {
  const mainPageRef = useRef();
  const chartPageRef = useRef();
  const pageRefs = useRef([]);

  const [actionSdkResponse, setActionSdkResponse] = useState();

  const [firstPageReportData, setFirstPageReportData] = useState(null);
  const [splitPages, setSplitPages] = useState([]);

  const [generatePdfState, setGeneratePdfState] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const [verificationState, setVerificationState] = useState({
    isError: false,
    errorValues: null,
    errorType: "",
  });

  function clubCheck(input) {
    return input.toLowerCase().trim().split(" ")[0];
  }

  useEffect(() => {
    const hasDifferentStrings = (arr) => !arr.every((str) => clubCheck(str) === clubCheck(arr[0]));
    const hasDifferentClubs = (arr) => {
      const midIronClubsList = ["5i", "6i", "7i"];

      const allSame = arr.every((str) => clubCheck(str) === clubCheck(arr[0]));
      const allMidIron = arr.every((str) => midIronClubsList.includes(clubCheck(str)));

      return !(allSame || allMidIron);
    };
    const hasNotAnalyzed = (arr) => !arr.every((item) => item === true);

    if (credits <= 0) {
      setVerificationState({
        isError: true,
        errorValues: null,
        errorType: "missingCredits",
      });
    } else {
      setVerificationState({
        isError: false,
        errorValues: null,
        errorType: "",
      });
    }

    if (hasDifferentStrings(verificationUsers)) {
      setVerificationState({
        isError: true,
        errorValues: Array.from(new Set(verificationUsers)).join(", "),
        errorType: "mixedUsers",
      });
    }

    if (hasDifferentClubs(verificationClubs)) {
      setVerificationState({
        isError: true,
        errorValues: Array.from(new Set(verificationClubs)).join(", "),
        errorType: "mixedClubs",
      });
    }

    if (hasNotAnalyzed(verificationAnalysed)) {
      setVerificationState({
        isError: true,
        errorValues: [],
        errorType: "hasNotAnalyzed",
      });
    }

    if (verificationCameraAngles.includes("DTL")) {
      setVerificationState({
        isError: true,
        errorValues: "Down The Line videos are unsupported",
        errorType: "mixedCameraAngles",
      });
    }
  }, [
    verificationUsers,
    verificationClubs,
    verificationCameraAngles,
    verificationAnalysed,
    credits,
  ]);

  const generatePDF = async (userNameForFileName) => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = 210;

    const newRefArr = [mainPageRef, chartPageRef, ...pageRefs.current];

    const addPageToPDF = async (ref, isFirstPage) => {
      if (!ref.current) return;
      const canvas = await html2canvas(ref.current, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * pageWidth) / canvas.width;

      if (!isFirstPage) pdf.addPage();
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        pageWidth,
        imgHeight,
        undefined,
        "FAST"
      );
    };

    for (let i = 0; i < newRefArr.length; i++) {
      await addPageToPDF(newRefArr[i], i === 0);
    }

    const pdfBlob = new Blob([pdf.output("arraybuffer")], {
      type: "application/pdf",
    });

    const fileName = `${userNameForFileName}_${golfClub}_${moment().valueOf()}.pdf`;
    const pictureRef = admin
      .storage()
      .ref()
      .child(`swing-score-reports/${fileName}`);

    try {
      await pictureRef.put(pdfBlob);
      const reportURL = await pictureRef.getDownloadURL();

      const user = admin.auth().currentUser;
      const dataForReportDocCreation = {
        userId: userId,
        coachId: user.uid,
        clubCategory: golfClub === "D" ? "Driver" : "Iron",
        clubName: golfClub,
        reportURL,
        averageScore: 0,
        swingResults: {},
        selectedGoals: {},
        report: actionSdkResponse,
      };

      await axiosWithToken(functionBaseUrl + "/api/swing-score-report/v1/", {
        method: "post",
        data: dataForReportDocCreation,
      }).catch((err) => {
        console.error("Error creating report doc:", err);
      });

      const uid = admin.auth().currentUser.uid;
      await axiosWithToken(`${functionBaseUrl}/api/v1/credits`, {
        method: "POST",
        data: {
          amount: "0",
          creditCount: 1,
          status: "complete",
          userId: userId,
          coachId: uid,
          transactionType: "out",
          creditType: "reportCredits",
        },
      });

      // pdf.save(fileName);
      return reportURL;
    } catch (error) {
      console.error("Error uploading PDF to Firebase Storage:", error);
    }
  };

  const splitPagesRecursive = (page, blockLimit) => {
    const calculateBlockSize = (block) =>
      Math.ceil(block.indicatorGroup.length / 3);

    const processBlocks = (blocks) => {
      if (blocks.length === 0) {
        return [];
      }

      let currentPageBlocks = [];
      let blockCount = 0;

      for (let i = 0; i < blocks.length; i++) {
        const blockName = blocks[i].name;

        const blockSize = calculateBlockSize(blocks[i]);

        if (blockCount + blockSize > blockLimit) {
          return [
            {
              ...page,
              indicatorGroups: currentPageBlocks,
            },
            ...processBlocks(blocks.slice(i)),
          ];
        }

        currentPageBlocks.push(blocks[i]);
        blockCount += blockName === "Sequencing" ? 1 : blockSize;
      }

      return [
        {
          ...page,
          indicatorGroups: currentPageBlocks,
        },
      ];
    };

    return processBlocks(page.indicatorGroups);
  };

  const splitAllPages = (pagesTest, blockLimit) => {
    return pagesTest.flatMap((page) => splitPagesRecursive(page, blockLimit));
  };

  const sortIndicatorCardsBasedOnIndicatorGroup = (groupName, block) => {
    const parseIndicatorArray = (indicatorNameSubstrings) => {
      const arr = [];
      for (const indName of indicatorNameSubstrings) {
        const val = block.indicatorGroup.find((el) =>
          el.name.includes(indName)
        );
        if (val) arr.push(val);
      }

      return arr;
    };

    switch (groupName) {
      case "Rotational ROM":
        return parseIndicatorArray(["Chest", "Pelvis", "X-Factor"]);
      case "Horizontals":
        return parseIndicatorArray(["Chest", "Pelvis"]);
      case "Verticals":
        return parseIndicatorArray(["Drop", "Lift"]);
      case "Rotational Speed":
        return parseIndicatorArray(["Pelvis", "Chest", "Arm", "Shaft"]);
      case "Release":
        return parseIndicatorArray(["Angle", "Release", "Gain"]);
      case "Contributions":
        return parseIndicatorArray(["Legs", "Core", "Shoulder", "Wrist"]);
      case "Gain Factors":
        return parseIndicatorArray(["Core", "Shoulder", "Wrist", "Release"]);
      case "Address":
        return parseIndicatorArray([
          "Chest Turn",
          "Pelvis Turn",
          "Chest Bend",
          "Chest Side Bend",
          "Pelvis Side Bend",
        ]);
      case "Top of Backswing":
        return parseIndicatorArray([
          "Chest Turn",
          "Pelvis Turn",
          "Chest Bend",
          "Chest Side Bend",
          "Pelvis Side Bend",
          "Sway Gap",
          "Chest Sway",
          "Pelvis Sway",
          "Chest Lift",
          "Pelvis Lift",
          "Hand Sway",
          "Hand Lift",
        ]);
      case "Transition":
        return parseIndicatorArray(["Pelvis", "Chest", "Arm", "Tempo"]);
      case "Impact":
        return parseIndicatorArray([
          "Chest Turn",
          "Pelvis Turn",
          "Chest Bend",
          "Chest Side Bend",
          "Pelvis Side Bend",
          "Sway Gap",
          "Chest Sway",
          "Pelvis Sway",
          "Chest Lift",
          "Pelvis Lift",
          "Hand Sway",
          "Hand Lift",
        ]);
      default:
        return block.indicatorGroup;
    }
  };

  const renderPages = () => {
    return splitPages.map((el, i) => (
      <ReportPageTemplate
        key={`page-${i}`}
        pageRef={pageRefs.current[i]}
        club={"Driver"}
        date={moment().format("MM/DD/YYYY")}
        firstData={el.firstData}
        secondData={el.secondData}
        page={i + 3}
        totalPages={splitPages.length + 2}
        pageName={el.pageName}
        userName={userName}
        indicatorBlocks={el.indicatorGroups.map((block, blockIndex) => (
          <ReportIndicatorCardGroup
            key={`block-${blockIndex}`}
            groupName={block.name}
            percentage={
              block.avgPercentile ||
              block.avgConsistencyScore ||
              block.avgEfficiencyScore ||
              0
            }
            cards={
              block.name === "Sequencing"
                ? [
                    <ReportIndicatorCardSequencing
                      percentage={el.transitionOrderSequenceScore.toFixed(0)}
                      indicatorName="Transition Order"
                      indicatorsArray={block.indicatorGroup}
                      valueToBaseColorOn={el.transitionOrderSequenceScore}
                    />,
                    <ReportIndicatorCardSequencing
                      percentage={el.movementSequenceOrderScore.toFixed(0)}
                      indicatorName="Pelvis Movement Order"
                      indicatorsArray={block.indicatorGroup}
                      valueToBaseColorOn={el.movementSequenceOrderScore}
                    />,
                  ]
                : sortIndicatorCardsBasedOnIndicatorGroup(
                    block.name,
                    block
                  ).map((indicator, cardIndex) => {
                    // console.log("hehehehe", indicator.zScore.color);
                    return (
                      <ReportIndicatorCard
                        showAdditionalData={
                          el.pageName === "Consistency Report" ||
                          block.name === "Sequencing"
                            ? false
                            : true
                        }
                        showIcons={
                          el.pageName === "Consistency Report" ? false : true
                        }
                        key={`indicator-${cardIndex}`}
                        indicatorName={indicator.name}
                        percentage={indicator.percentileRank}
                        indicatorValue={
                          indicator.value
                          // el.pageName === "Consistency Report"
                          //   ? indicator.zScore.zScore
                          //   : indicator.value
                        }
                        tourRange={`${(
                          indicator.indicatorRange -
                          indicator.indicatorStDeviation
                        ).toFixed(1)} to ${(
                          indicator.indicatorRange +
                          indicator.indicatorStDeviation
                        ).toFixed(1)}`}
                        zScore={indicator?.zScore?.zScore}
                        color={indicator?.zScore?.color?.i2_1.toLowerCase()}
                        unit={indicator.unit}
                      />
                    );
                  })
            }
          />
        ))}
      />
    ));
  };

  const fetchDataForGeneration = async (
    videoIdsForRequest,
    golfClubForRequest,
    genderForRequest,
    heightForRequest
  ) => {
    try {
      const data = await axiosWithToken(
        functionBaseUrl + "/api/swing-library/pdf-report",
        {
          method: "post",
          data: {
            videoIds: videoIdsForRequest,
            gender: genderForRequest,
            height: heightForRequest,
            golfClub: golfClubForRequest,
          },
        }
      );

      setActionSdkResponse(data.data);

      setFirstPageReportData({
        overallScore: data.data.overallScore,
        swingType: data.data.swingType,
        descriptionSections: data.data.reportSection,
        speedScore: data.data.speedPercentile.clubHeadSpeedPercentileScore,
        efficiencyScore: data.data.efficiencyScore.efficiencyScore,
        consistencyScore: data.data.consistencyScore.consistencyScore,
        clubSpeed: data.data.speedPercentile.clubHeadSpeedAvgIndicatorValue,
      });

      const split = splitAllPages(
        [
          {
            ...data.data.speedPercentile,
            firstData: {
              value:
                data.data.speedPercentile.clubHeadSpeedPercentileScore.toFixed(
                  0
                ),
              name: "Speed Score",
            },
            secondData: {
              value: `${data.data.speedPercentile.clubHeadSpeedAvgIndicatorValue.toFixed(
                0
              )} mph`,
              name: "Avg Club Head Speed",
            },
            pageName: "Speed Report",
            indicatorGroups: [
              data.data.speedPercentile.rotROMGroup,
              data.data.speedPercentile.horizontalsGroup,
              data.data.speedPercentile.verticalsGroup,
              data.data.speedPercentile.rotSpdGroup,
              data.data.speedPercentile.releaseGroup,
            ],
          },
          {
            ...data.data.efficiencyScore,
            firstData: {
              value: data.data.efficiencyScore.efficiencyScore.toFixed(0),
              name: "Efficiency Score",
            },
            pageName: "Efficiency Report",
            indicatorGroups: [
              data.data.efficiencyScore.contributionGroup,
              data.data.efficiencyScore.gainFactorGroup,
              data.data.efficiencyScore.sequencingGroup,
            ],
          },
          {
            ...data.data.consistencyScore,
            firstData: {
              value: data.data.consistencyScore.consistencyScore.toFixed(0),
              name: "Consistency Score",
            },
            secondData: {
              value: `#${videoIds.length}`,
              name: "Swings Taken",
            },
            pageName: "Consistency Report",
            indicatorGroups: [
              data.data.consistencyScore.adrConsistencyGroup,
              data.data.consistencyScore.topConsistencyGroup,
              data.data.consistencyScore.transitionConsistencyGroup,
              data.data.consistencyScore.impConsistencyGroup,
            ],
          },
        ],
        7
      );
      setSplitPages(split);

      pageRefs.current = split.map(() => React.createRef());
      return true;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message ||
        err?.message ||
        "An unexpected error occurred. Please try again later.";
      console.log(err);
      Swal.fire({
        title: '<p style="font-size:70%;">' + errorMessage + "</p>",
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          container: "my-swal",
        },
      });
      setOpenDialog(false);
      return false;
    }
  };

  const startPdfGenerationPipeline = useCallback(async () => {
    setGeneratePdfState(1);
    //const uid = admin.auth().currentUser.uid;
    const success = await fetchDataForGeneration(
      videoIds,
      golfClub,
      gender,
      height
    );
    if (success) {
      setGeneratePdfState(2);
    } else {
      setGeneratePdfState(0);
    }
  }, [videoIds, golfClub, gender, height, pageRefs]);

  const startPdfDownload = useCallback(async () => {
    setGeneratePdfState(3);

    const reportURL = await generatePDF(userName);
    if (isTest) console.log(reportURL);
    setGeneratePdfState(0);
    return reportURL;
  }, [userName, actionSdkResponse]);

  return (
    <>
      {(videoIds === null || videoIds.length < 3) && (
        <Tooltip title="You need to select more than 3 swings with the same golf club and golfer">
          <span>
            <button
              onClick={() => ""}
              disabled={true}
              style={{
                padding: "12px 16px",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Manrope",
                cursor: "not-allowed",
                backgroundColor: "#F2F4F7",
                border: "none",
                borderRadius: "64px",
                cursor: "not-allowed",
              }}
            >
              Create Report
            </button>
          </span>
        </Tooltip>
      )}

      {videoIds?.length >= 3 && (
        <>
          <button
            onClick={
              isTest
                ? () => startPdfGenerationPipeline()
                : () => setOpenDialog(true)
            }
            style={{
              padding: "12px 16px",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Manrope",
              cursor: "pointer",
              backgroundColor: "#F2F4F7",
              border: "none",
              borderRadius: "64px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            Create Report
          </button>
          {isTest && <button onClick={startPdfDownload}>download</button>}
        </>
      )}

      <ReportGenerationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        credits={credits}
        onPrepare={startPdfGenerationPipeline}
        onCreditSpent={startPdfDownload}
        verificationState={verificationState}
      />

      <div
        style={{
          position: "absolute",
          left: isTest ? "500px" : "5000px",
          fontFamily: "Manrope",
        }}
      >
        {firstPageReportData && (
          <>
            <ReportMainPage
              pageRef={mainPageRef}
              club={golfClub}
              date={moment().format("DD MMM, YYYY")}
              overallScore={firstPageReportData.overallScore.toFixed(0)}
              userName={userName}
              totalPages={splitPages.length + 2}
              speedScore={firstPageReportData.speedScore.toFixed(0)}
              clubSpeed={`${firstPageReportData.clubSpeed.toFixed(0)} mph`}
              efficiencyScore={firstPageReportData.efficiencyScore.toFixed(0)}
              consistencyScore={firstPageReportData.consistencyScore.toFixed(0)}
              descriptionSections={firstPageReportData.descriptionSections}
              swingsNumber={videoIds.length}
              swingType={firstPageReportData.swingType}
            />
            <ReportChartPage
              pageRef={chartPageRef}
              totalPages={splitPages.length + 2}
            />
          </>
        )}

        {renderPages()}
      </div>
    </>
  );
};
