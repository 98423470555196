import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";

const styles = {
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 16px 0",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  icon: {
    width: "38px",
    height: "38px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "18px",
  },
  dialogContent: {
    padding: "16px",
    fontSize: "16px",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  },
  link: {
    color: "#5B21B6",
    textDecoration: "none",
    fontWeight: 600,
    cursor: "pointer",
  },
  primaryButton: {
    width: "100%",
    marginTop: "8px",
    backgroundColor: "#6D28D9",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 600,
    padding: "10px",
    borderRadius: "36px",
    border: "none",
    cursor: "pointer",
    textAlign: "center",
  },
  secondaryButton: {
    width: "100%",
    marginTop: "8px",
    backgroundColor: "#F2F4F7",
    fontSize: "14px",
    fontWeight: 600,
    padding: "10px",
    borderRadius: "36px",
    border: "none",
    cursor: "pointer",
    textAlign: "center",
  },
};

const ConfirmationDialog = ({ onClose, onConfirm, credits, loading }) => (
  <>
    <DialogTitle disableTypography style={styles.dialogTitle}>
      <div style={styles.iconWrapper}>
        <div style={{ ...styles.icon, backgroundColor: "#EDE9FE" }}>
          <ErrorOutlineIcon />
        </div>
      </div>
      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent style={styles.dialogContent}>
      <Typography style={{ fontWeight: 600 }} variant="h6">
        You're about to use 1 swing score credit
      </Typography>
      <Typography variant="body2">
        Are you sure you want to generate a Swing Score Report?
      </Typography>
      <Typography variant="body2" style={{ fontWeight: 600, marginTop: "8px" }}>
        You have {credits} credits available
      </Typography>
    </DialogContent>

    <DialogActions
      style={{
        justifyContent: "center",
        padding: "16px",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <button style={styles.primaryButton} disabled={true}>
          Generating
        </button>
      ) : (
        <button style={styles.primaryButton} onClick={onConfirm}>
          Use 1 Credit
        </button>
      )}
    </DialogActions>
  </>
);

const SuccessDialog = ({ onClose, reportURL, loading }) => (
  <>
    <DialogTitle disableTypography style={styles.dialogTitle}>
      <div style={styles.iconWrapper}>
        <div style={{ ...styles.icon, backgroundColor: "#EDE9FE" }}>
          <CheckIcon />
        </div>
      </div>
      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent style={styles.dialogContent}>
      <Typography style={{ fontWeight: 600 }} variant="h6">
        Swing Score Report Generated
      </Typography>
      <Typography variant="body2">
        You can access the swing score report at any time in your recent usage
        in Credits.
      </Typography>
      <Typography variant="body2" style={{ fontWeight: 600, marginTop: "8px" }}>
        <a href="/credits" style={styles.link}>
          Credits ↗
        </a>
      </Typography>
    </DialogContent>

    <DialogActions
      style={{
        justifyContent: "center",
        padding: "16px",
        display: "flex",
        gap: "10px",
      }}
    >
      <button
        onClick={() => {
          window.open(reportURL, "_blank");
        }}
        style={styles.secondaryButton}
      >
        View
      </button>
      <button
        style={styles.primaryButton}
        onClick={() => {
          if (!reportURL) return;
          downloadFile(reportURL, "Swing Score Report.pdf");
        }}
      >
        {loading ? "Downloading" : "Download PDF"}
      </button>
    </DialogActions>
  </>
);

const ErrorDialog = ({ onClose, credits, verificationState }) => {
  const textDataMapped = {
    missingCredits: {
      title: "Oops!",
      description:
        "It looks like you don't have any credits available to generate a Swing Score Report",
      additionalText: `You have ${credits} credits available`,
      buttonText: "Get Credits",
    },
    mixedUsers: {
      title: "Mixed Golfers",
      description: "It looks like you selected videos with different golfers: ",
      additionalText:
        "Please select videos that are from the same golfer to generate an accurate report.",
      buttonText: "Try Again",
    },
    mixedClubs: {
      title: "Incompatible Mixed Clubs",
      description:
        "It looks like you selected videos with different club types: ",
      additionalText:
        "Please select videos that are Driver only, or 5/6/7 Irons",
      buttonText: "Try Again",
    },
    hasNotAnalyzed: {
      title: "Not Analyzed Videos",
      description: "It looks like you selected not analyzed video(s).",
      additionalText:
        "Please select videos that are analyzed to generate an accurate report.",
      buttonText: "Try Again",
    },
    mixedCameraAngles: {
      title: "Down the Line videos",
      description:
        "It looks like you selected videos that are recorded from Down The Line: ",
      additionalText:
        "Please select videos that are from Face On angle to generate an accurate report.",
      buttonText: "Try Again",
    },
  };

  return (
    <>
      <DialogTitle disableTypography style={styles.dialogTitle}>
        <div style={styles.iconWrapper}>
          <div style={{ ...styles.icon, backgroundColor: "#FFF3CD" }}>
            <ErrorOutlineIcon style={{ color: "#B54708" }} />
          </div>
        </div>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent style={styles.dialogContent}>
        <Typography style={{ fontWeight: 600 }} variant="h6">
          {textDataMapped[verificationState.errorType]?.title}
        </Typography>
        <Typography variant="body2">
          {textDataMapped[verificationState.errorType]?.description}
        </Typography>
        {verificationState.errorValues ? (
          <Typography variant="body2">
            {verificationState.errorValues}
          </Typography>
        ) : (
          <></>
        )}
        <Typography
          variant="body2"
          style={{ fontWeight: 600, marginTop: "8px" }}
        >
          {textDataMapped[verificationState.errorType]?.additionalText}
        </Typography>
      </DialogContent>

      {verificationState.errorType === "missingCredits" ? (
        <DialogActions
          style={{
            justifyContent: "center",
            padding: "16px",
            flexDirection: "column",
          }}
        >
          <a href="/credits" style={styles.primaryButton}>
            {textDataMapped[verificationState.errorType]?.buttonText}
          </a>
        </DialogActions>
      ) : (
        <DialogActions
          style={{
            justifyContent: "center",
            padding: "16px",
            flexDirection: "column",
          }}
        >
          <div onClick={onClose} style={styles.primaryButton}>
            {textDataMapped[verificationState.errorType]?.buttonText}
          </div>
        </DialogActions>
      )}
    </>
  );
};

function downloadFile(url, filename) {
  // Use axios to fetch the file
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      // Create a Blob URL for the file
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename; // Set the suggested filename
      document.body.appendChild(a); // Append the link to the DOM
      a.click(); // Simulate a click to trigger the download

      // Clean up: remove the link and revoke the Blob URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error("Download failed:", error);
    });
}

export const ReportGenerationDialog = ({
  open,
  onClose,
  credits,
  onPrepare,
  onCreditSpent,
  verificationState,
}) => {
  const [dialogState, setDialogState] = useState("confirmation");
  const [loading, setLoading] = useState(false);
  const [readyReportUrl, setReadyReportUrl] = useState("");
  const [preparationLoading, setPreparationLoading] = useState(false);

  // useEffect(() => {
  //   console.log(dialogState, verificationState);
  // }, [dialogState, verificationState]);

  useEffect(() => {
    if (!verificationState.isError) {
      setDialogState("confirmation");
    }

    if (verificationState.isError && verificationState.errorType) {
      setDialogState("error");
    }

    if (open && !verificationState.isError) {
      (async () => {
        setPreparationLoading(true);
        await onPrepare();
        setPreparationLoading(false);
      })();
    }
  }, [open, verificationState]);

  const handleClose = () => {
    onClose();
    if (dialogState === "success") {
      window.location.reload();
    }
    setDialogState("confirmation");
  };

  const handleGenerate = async () => {
    setLoading(true);
    const reportURL = await onCreditSpent();
    setReadyReportUrl(reportURL);
    setLoading(false);
    setDialogState("success");
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      {preparationLoading ? (
        <div
          style={{
            padding: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {dialogState === "confirmation" && (
            <ConfirmationDialog
              onClose={handleClose}
              credits={credits}
              onConfirm={handleGenerate}
              loading={loading}
            />
          )}

          {dialogState === "success" && (
            <SuccessDialog
              onClose={handleClose}
              reportURL={readyReportUrl}
              loading={loading}
            />
          )}

          {dialogState === "error" && (
            <ErrorDialog
              onClose={handleClose}
              credits={credits}
              verificationState={verificationState}
            />
          )}
        </>
      )}
    </Dialog>
  );
};
