import React, { useState } from "react";
import MaterialTable from "material-table";
import { IconButton, TablePagination } from "@material-ui/core";
import { forwardRef } from "react";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from "@material-ui/icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import "./style.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

function CustomPagination({
  pageSize,
  page,
  totalRows,
  onPageChange,
  onPageSizeChange,
}) {
  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    onPageSizeChange(parseInt(event.target.value, 10));
    onPageChange(0); // Reset to first page
  };

  const handleFirstPageButtonClick = () => {
    onPageChange(0);
  };

  const handleLastPageButtonClick = () => {
    const lastPage = Math.ceil(totalRows / pageSize) - 1;
    onPageChange(lastPage);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon />
      </IconButton>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      {/*<IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(totalRows / pageSize) - 1}
      >
        <LastPageIcon />
      </IconButton>*/}
    </div>
  );
}

function SwingsComponent({
  videos,
  analysisAggregates,
  onPageSizeChange,
  onSelectionChange,
  onOrderChange,
  pageSize,
  totalSwings,
  currentPage,
  onPageChange,
  columns,
}) {
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);

  const statsRows = [
    {
      videoPath:
        selectedRowsCount > 0
          ? "Averages \n(" + selectedRowsCount + ") Selected"
          : "Averages",
      videoCreated: "",
      userData: { golfClub: "", fullName: "" },
      analysis: {
        indicators: Object.fromEntries(
          Object.entries(analysisAggregates).map(([key, value]) => [
            key,
            value.avg,
          ])
        ),
      },
    },
    {
      videoPath:
        selectedRowsCount > 0
          ? "Z Score \n(" + selectedRowsCount + ") Selected"
          : "Z Score",
      videoCreated: "",
      userData: { golfClub: "", fullName: "" },
      analysis: {
        indicators: Object.fromEntries(
          Object.entries(analysisAggregates).map(([key, value]) => [
            key,
            value.std,
          ])
        ),
      },
    },
  ];

  const paginatedData = () => {
    return [...statsRows, ...videos];
  };

  return (
    <>
      <MaterialTable
        style={{ marginTop: "10px", marginBottom: "10px" }}
        icons={tableIcons}
        columns={columns}
        data={paginatedData()}
        title={""}
        options={{
          selection: true,
          sorting: true,
          search: false,
          paging: false,
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.videoPath === "Averages" ||
              rowData.videoPath === "Z Score"
                ? "#f5f5f5"
                : "#fff",
          }),
          selectionProps: (rowData) => ({
            disabled:
              rowData.videoPath.includes("Averages") ||
              rowData.videoPath.includes("Z Score"),
            style: {
              visibility:
                rowData.videoPath.includes("Averages") ||
                rowData.videoPath.includes("Z Score")
                  ? "hidden"
                  : "visible",
            },
          }),
        }}
        onOrderChange={(e, dir) => onOrderChange(e, dir)}
        onSelectionChange={(rows) => {
          setSelectedRowsCount(rows.length);
          onSelectionChange(rows);
        }}
      />
      <CustomPagination
        pageSize={pageSize}
        page={currentPage}
        totalRows={totalSwings}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </>
  );
}

export const Swings = SwingsComponent;
