import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  InputLabel,
  Box,
  Card,
  CardContent,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { StripePayment } from "../../components/stripePayment";
import { makeStyles } from "@material-ui/core/styles";

const PlanCard = ({ onClick, mainText, secondaryText, isSelected }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "8px",
        width: "45%",
        alignItems: "center",
        border: isSelected ? "#30077C 1px solid" : "#D0D5DD 1px solid",
        borderRadius: "8px",
        fontWeight: "600",
        background: isSelected ? "#F9F5FF" : "white",
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={onClick}
    >
      <div
        style={{ color: isSelected ? "#30077C" : "black", fontSize: "14px" }}
      >
        {mainText}
      </div>
      <div
        style={{ color: isSelected ? "#30077C" : "#667085", fontSize: "12px" }}
      >
        {secondaryText}
      </div>
    </div>
  );
};

function BuyCreditsComponent({
  open,
  close,
  handleResult,
  user,
  custom,
  amount,
  credits,
  handlePackageChange,
  creditType,
}) {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
    },
    text: {
      fontFamily: "Manrope",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      letterSpacing: "0em",
      textAlign: "center",
      color: "#101828",
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Dialog fullWidth open={open} onClose={close}>
        <DialogTitle>
          <div className={classes.container}>
            <Typography className={classes.text}>Payment Method</Typography>
            <CloseIcon onClick={close} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                border={1}
                borderColor="primary.main"
                style={{ marginBottom: "5%" }}
              >
                <Card style={{ minWidth: "50%" }}>
                  {creditType === "studentCredits" ? (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        justifyContent: "space-between",
                        padding: "20px",
                      }}
                    >
                      <PlanCard
                        mainText="30 Months ($300)"
                        secondaryText="$10/monthly credit"
                        onClick={() => handlePackageChange(300)}
                        isSelected={amount === 300 && creditType === "studentCredits"}
                      />
                      <PlanCard
                        mainText="60 Months ($500)"
                        secondaryText="$8.33/monthly credit"
                        onClick={() => handlePackageChange(500)}
                        isSelected={amount === 500 && creditType === "studentCredits"}
                      />
                      <PlanCard
                        mainText="100 Months ($700)"
                        secondaryText="$7/monthly credit"
                        onClick={() => handlePackageChange(700)}
                        isSelected={amount === 700 && creditType === "studentCredits"}
                      />
                      <PlanCard
                        mainText="360 Months ($1800)"
                        secondaryText="$5/monthly credit"
                        onClick={() => handlePackageChange(1800)}
                        isSelected={amount === 1800 && creditType === "studentCredits"}
                      />
                    </div>) : (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        justifyContent: "space-between",
                        padding: "20px",
                      }}
                    >
                      <PlanCard
                        mainText="5 Reports ($250)"
                        secondaryText="$50/report"
                        onClick={() => handlePackageChange(250)}
                        isSelected={amount === 250 && creditType === "reportCredits"}
                      />
                      <PlanCard
                        mainText="10 Reports ($475)"
                        secondaryText="5% OFF - $48/report"
                        onClick={() => handlePackageChange(475)}
                        isSelected={amount === 475 && creditType === "reportCredits"}
                      />
                      <PlanCard
                        mainText="20 Reports ($900)"
                        secondaryText="10% OFF - $45/report"
                        onClick={() => handlePackageChange(900)}
                        isSelected={amount === 900 && creditType === "reportCredits"}
                      />
                      <PlanCard
                        mainText="30 Reports ($1,275)"
                        secondaryText="15% OFF - $42/report"
                        onClick={() => handlePackageChange(1275)}
                        isSelected={amount === 1275 && creditType === "reportCredits"}
                      />
                      <PlanCard
                        mainText="40 Reports ($1,600)"
                        secondaryText="20% OFF - $40/report"
                        onClick={() => handlePackageChange(1600)}
                        isSelected={amount === 1600 && creditType === "reportCredits"}
                      />
                      <PlanCard
                        mainText="50 Reports ($1,875)"
                        secondaryText="25% OFF - $38/report"
                        onClick={() => handlePackageChange(1875)}
                        isSelected={amount === 1875 && creditType === "reportCredits"}
                      />
                    </div>)
                  }
                </Card>
                {amount !== 0 && (
                  <Card>
                    <CardContent fullWidth>
                      <Typography
                        style={{
                          textAlign: "center",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <div className={classes.text}>
                          <span style={{ fontSize: "28px" }}>
                            Amount: <b>${amount}</b>
                          </span>
                          <br />
                          <p style={{ fontSize: "20px", color: "gray" }}>
                            {credits} {creditType === "reportCredits" ? "(reports)" : "(months)"}
                          </p>
                        </div>
                      </Typography>
                      {amount > 0 && (
                        <StripePayment
                          amount={amount}
                          handleResult={handleResult}
                          //useConfirmation={true}
                          name={user?.userName}
                          email={user?.email}
                        />
                      )}
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export const BuyCredits = BuyCreditsComponent;
