/**
 * ExportDialog.js
 * Displays options for export/download and exports/downloads data
 */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Switch,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import JSZip from "jszip";
import axios from "axios";
import _ from "lodash";
import { typeToLabel } from "./ListItems";
import moment from "moment";
import Swal from "sweetalert2";
import firebase from "../../../../common/firebase";

const isExclusive = (obj) => {
  return Object.values(obj).filter((v) => v).length === 1;
};

//Keys that should not be included in any export.
const blackListKeys = ["doc_relations", "docType"];

//Max name length for downloading status
const MAX_NAME_LENGTH = 30;

const defaultState = {
  toExport: { users: false, sessions: false, videos: false, analysis: false },
  useHeaders: true,
  statuses: {},
  downloading: 0,
  loading: false,
};

class ExportDialog extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.state.toExport[this.props.target] = true;
    this.state.target = this.props.target;
  }
  shouldComponentUpdate(nextProps) {
    return nextProps.open || this.props.open;
  }

  componentDidUpdate(prevProps) {
    //Change which checkbox is default selected depending on the target
    if (this.state.target !== this.props.target) {
      const toExport = {
        users: false,
        sessions: false,
        videos: false,
        analysis: false,
      };
      toExport[this.props.target] = true;
      this.setState({ toExport: toExport, target: this.props.target });
    }
  }

  //Downloads all videos in the selected list. Sets state.statuses to 1 for each video that is downloaded.
  downloadVideos = () => {
    const statuses = {};
    const names = Object.entries(this.props.selected).map(([id, v]) => {
      statuses[id] = 0;
      return (
        v.videos?.videoOrigName +
        (v.videos?.videoType !== "video/*" ? "." + v.videos?.videoType : "")
      );
    });
    this.setState({ downloading: 1, statuses: statuses });
    const promises = Object.entries(this.props.selected).map(([id, obj]) => {
      const video = obj.videos;
      //Get video from url and return as a blob
      return axios({
        url: video.videoPath,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          const stats = this.state.statuses;
          stats[id] = 1;
          this.setState({ statuses: stats });
          return res;
        })
        .catch((err) => {
          console.log("Failed to download " + video.videoOrigName);
          const stats = this.state.statuses;
          stats[id] = 2;
          this.setState({ statuses: stats });
          return null;
        });
    });
    //If only one item is selected, do not zip the file and just download it
    if (promises.length === 1) {
      promises[0].then((response) => {
        if (response) {
          this.setState({ downloading: 3 });
          this.props.downloadFile(names[0], response.data);
        }
      });
    } else {
      //Zip all video files and download them
      Promise.all(promises).then((responses) => {
        const zip = new JSZip();
        this.setState({ downloading: 2 });
        responses.forEach((res, index) => {
          if (res) {
            zip.file(names[index], res.data);
          }
        });
        this.setState({ downloading: 3 });
        zip.generateAsync({ type: "blob" }).then((blob) => {
          this.setState({ downloading: 0 });
          this.props.downloadFile("videos.zip", blob);
        });
      });
    }
  };

  //Generates the CSV headers based on the passed in object.
  makeCSVHeader = (data, label = "") => {
    let output = [];
    if (data instanceof Object && !(data instanceof Array)) {
      Object.entries(data).forEach(([key, value]) => {
        if (!blackListKeys.includes(key))
          output = output.concat(
            this.makeCSVHeader(value, `${label}${label ? "." : ""}${key}`)
          );
      });
    } else {
      output.push(label);
    }
    return output.sort();
  };

  //Generates CSV data based on the headers passed in and the data
  makeCSV = (data, headers) => {
    let output = [];
    let headerPre = "";
    if (isExclusive(this.state.toExport) && !this.state.useHeaders) {
      headerPre = Object.keys(this.state.toExport).find(
        (v) => this.state.toExport[v]
      );
    }
    headers.forEach((header) => {
      if (header.includes("hipMeasurementMM")) {
        output.push(
          Math.round(
            _.get(
              data,
              (headerPre ? headerPre + "." : "") +
                "videos.userData.hipMeasurement"
            ) * 25.4
          )
        );
      } else {
        let value = _.get(data, (headerPre ? headerPre + "." : "") + header);
        if (typeof value === "number" && String(value).length === 13) {
          value = moment(String(value), "x").format("MM/DD/YYYY");
        } else if (value === undefined) {
          value = "";
        }
        if (
          header.includes(".sessionIds") ||
          header.includes(".students") ||
          header.includes(".coaches")
        ) {
          output.push('"' + value?.length + '"');
        } else {
          output.push('"' + value?.toString().replaceAll('"', "″") + '"');
        }
      }
    });
    return output;
  };

  //Generates the CSV file and downloads it
  exportSelected = async (ML) => {
    Swal.fire({
      title: "Exporting Data",
      html: '<div><img width="10%" src="images/loading.gif" alt="Loading" /></div>',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      customClass: {
        container: "my-swal",
      },
    });

    let headers = [];
    let headerLabels = [];
    this.setState({ loading: true });

    if (ML) {
      headerLabels =
        "Swing Name,User Account,Session Name,Phone Recorded,FPS,Intrinsic,Pelvis Width (inches),Pelvis Width (mm),Dominant Hand,Height,Video ID,Video URL";
      headers = [
        "videos:videoOrigName",
        "videos:userData.fullName",
        "sessions:sessionName",
        "videos:metaData.model",
        "videos:metaData.fps",
        "videos:intrinsicVerbose",
        "videos:userData.hipMeasurement",
        "videos:userData.hipMeasurementMM",
        "videos:userData.hand",
        "videos:userData.height",
        "videos:_id",
        "videos:videoPath",
      ];

      headers = headers.map((v) => v.replace(":", "."));
    } else {
      // For normal CSV export
      if (this.state.useHeaders) {
        headers = this.props.headCells.map((v) => v.replace(":", "."));
      } else {
        Object.entries(this.state.toExport).forEach(([key, enabled]) => {
          if (enabled) {
            headers = headers.concat(
              this.makeCSVHeader(
                this.props.selected[Object.keys(this.props.selected)[0]][key],
                isExclusive(this.state.toExport) ? "" : key
              )
            );
          }
        });
      }
      headerLabels = headers.join(",");
    }

    let blob;
    if (this.props.open === "all") {
      blob = await this.props.exportAll(headers, headerLabels);
    } else {
      let output = [headerLabels];
      const promises = [];
      const swingDataMap = {};
      // Iterate through selected entries to fetch swing data
      Object.entries(this.props.selected).forEach(([key, value]) => {
        const swingID = value?.videos?._id;

        if (swingID) {
          const storageRef = firebase
            .storage()
            .ref()
            .child("/swing_data/" + swingID);
          const swingPromise = storageRef
            .listAll()
            .then(async (res) => {
              if (!res.items.length) {
                swingDataMap[swingID] = ""; // Store an empty value if no swing data is found
                return;
              }
              for (const itemRef of res.items) {
                if (itemRef.name.includes("_ai.json")) {
                  const itemRes = await itemRef.getDownloadURL();
                  swingDataMap[swingID] = itemRes;
                  return;
                }
              }
            })
            .catch((error) => {
              console.error(
                `Error fetching swing data for ID: ${swingID}`,
                error
              );
              swingDataMap[swingID] = ""; // Handle errors by storing an empty value
            });

          promises.push(swingPromise);
        }
      });

      await Promise.all(promises);

      // Construct the final output with swing data
      Object.entries(this.props.selected).forEach(([key, value]) => {
        const swingID = value?.videos?._id;
        const row = this.makeCSV(value, headers);
        row.push(swingDataMap[swingID]);
        output.push(row.join(","));
      });
      output[0] = output[0] + ",Swing Data";
      blob = new Blob([output.join("\n")]);
    }

    this.setState({ loading: false });

    if (blob) {
      this.props.downloadFile("export.csv", blob);
    }
    Swal.close();
  };

  exportMLFormat = () => {
    let headers = [
      "videos:videoOrigName",
      "videos:userData.fullName",
      "sessions:sessionName",
      "videos:metaData.model",
      "videos:metaData.fps",
      "videos:intrinsicVerbose",
      "videos:userData.hipMeasurement",
      "videos:userData.hipMeasurementMM",
      "videos:userData.hand",
      "videos:userData.height",
      "videos:_id",
      "videos:videoPath",
    ];
    headers = headers.map((v) => v.replace(":", "."));
    const output = [
      "Swing Name,User Account,Session Name,Phone Recorded,FPS,Intrinsic,Pelvis Width (inches),Pelvis Width (mm),Dominant Hand,Height,Video ID,Video URL" +
        "\n",
    ];
    Object.entries(this.props.selected).forEach(([key, value]) => {
      output.push(this.makeCSV(value, headers).join(",") + "\n");
    });
    const blob = new Blob(output);
    this.props.downloadFile("export.csv", blob);
  };
  handleClear = () => {
    this.setState({ ...defaultState, target: this.props.target });
    this.props.setSelected({});
    this.props.handleClose();
  };
  render() {
    const numDone = Object.values(this.state.statuses).filter(
      (value) => value === 1
    ).length;
    const numTotal = Object.keys(this.props.selected).length;
    return (
      <Dialog open={Boolean(this.props.open)} onClose={this.props.handleClose}>
        <DialogTitle>Export/Download</DialogTitle>
        <DialogContent style={{ minWidth: "500" }}>
          {this.state.downloading === 0 ? (
            <Grid
              container
              style={{
                display: this.props.open === "selected" ? "block" : "none",
              }}
            >
              {/*Show the export options*/}
              <Grid item xs={12}>
                <FormControlLabel
                  margin="none"
                  size="small"
                  label="Use Table Fields"
                  control={
                    <Switch
                      checked={this.state.useHeaders}
                      onChange={() => {
                        this.setState({ useHeaders: !this.state.useHeaders });
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {Object.entries(this.state.toExport).map(([key, enabled]) => (
                  <FormControlLabel
                    key={key}
                    margin="none"
                    size="small"
                    label={typeToLabel[key]}
                    style={{ marginBottom: -5, marginTop: -5 }}
                    disabled={this.state.useHeaders}
                    control={
                      <Checkbox
                        checked={enabled}
                        onClick={() =>
                          this.setState({
                            toExport: {
                              ...this.state.toExport,
                              [key]: !enabled,
                            },
                          })
                        }
                      />
                    }
                  />
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              {/*Show download statuses*/}
              <Grid item xs={12}>
                <Typography>
                  {
                    [
                      "Finished Downloading",
                      `Downloading Videos (${numDone}/${numTotal})`,
                      "Zipping Videos",
                      "Exporting Zip",
                    ][this.state.downloading]
                  }
                </Typography>
              </Grid>
              <Grid item xs={8} style={{ marginLeft: 15 }}>
                {Object.entries(this.state.statuses).map(([key, status]) => {
                  const video = this.props.selected[key]?.videos;
                  if (video) {
                    let name =
                      video.videoOrigName +
                      (video.videoType !== "video/*"
                        ? "." + video.videoType
                        : "");
                    name =
                      name.substring(0, MAX_NAME_LENGTH) +
                      (name.length > MAX_NAME_LENGTH ? "..." : "");
                    return (
                      <Typography
                        key={key}
                        style={{ opacity: status ? "100%" : "20%" }}
                      >
                        {name}: {["Downloading...", "Done", "Failed"][status]}
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        key={key}
                        style={{ opacity: status ? "100%" : "20%" }}
                      >
                        Unknown: {["Downloading...", "Done", "Failed"][status]}
                      </Typography>
                    );
                  }
                })}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Button
                style={{
                  display:
                    this.props.target === "videos" &&
                    this.props.open === "selected"
                      ? "block"
                      : "none",
                }}
                variant="contained"
                color="secondary"
                onClick={this.downloadVideos}
              >
                Download
              </Button>
            </div>
            <div>
              {this.props.isAdmin && this.props.target === "videos" && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.exportSelected(true)}
                  disabled={this.state.loading}
                >
                  Export ML Format
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={() => this.exportSelected(false)}
              >
                Export
              </Button>
              {this.props.open === "selected" && (
                <Button
                  disabled={this.state.loading}
                  onClick={this.handleClear}
                >
                  Clear
                </Button>
              )}
              <Button onClick={this.props.handleClose}>Close</Button>
            </div>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ExportDialog;
