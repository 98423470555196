import React, { forwardRef, useState, useEffect } from "react";

import {
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Receipt,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import "jspdf-autotable";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../common/firebase";
import moment from "moment";
import MaterialTable from "material-table";
import { BuyCredits } from "./BuyCredits";
import { CreditsOverview } from "./components/CreditsOverview";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import axios from "axios";
import PurchaseResultDialog from "./components/PurchaseResultDialog";
import { PurchaseHistoryButton } from "./components/PurchaseHistoryButton/PurchaseHistoryButton";

const tableIcons = {
  ExportCSV: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportPDF: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Receipt: forwardRef((props, ref) => <Receipt {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: "fit-content",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //flexWrap: 'wrap',
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "2%",
    //flexWrap: 'wrap',
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  text: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  heading: {
    fontFamily: "Manrope",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: 15,
  },
  card: {
    width: "30%",
    maxWidth: "320px",
    height: "260px",
    padding: theme.spacing(5),
    borderRadius: "12px",
    border: "1px solid #EAECF0",
    gap: "24px",
    background:
      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #EAECF0, #EAECF0)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  bigCard: {
    width: "100%",
    maxWidth: "808px",
    height: "260px",
    padding: "24px",
    borderRadius: "12px",
    border: "1px solid #CBB1FB",
    gap: "32px",
    marginLeft: "2%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#FCFAFF",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      marginTop: "5%",
      marginLeft: 0,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "0",
      right: "0",
      width: "25%",
      height: "50%",
      backgroundImage: `url("/images/icons/background_pattern_decorative.png")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  cardText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    //marginBottom: 10,
    color: "white",
  },
  bigCardText: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    //marginBottom: 10,
    //color: 'white',
  },
  displaySm: {
    fontFamily: "Manrope",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "38px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  displayMd: {
    fontFamily: "Manrope",
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    marginRight: 10,
  },
  button: {
    width: "fit-content",
    height: "36px",
    padding: "8px 14px",
    borderRadius: "64px",
    gap: "8px",
    background: "#5C0DEE",
    textTransform: "none",
    marginTop: 15,
    "&:hover": {
      background: "#8e5beb",
    },
  },
}));

function CreditsComponent({ history }) {
  const [id, setId] = useState("");
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(250);
  const [credits, setCredits] = useState(5);
  const [creditType, setCreditType] = useState("reportCredits");
  const [custom, setCustom] = useState(false);
  const [unpaidCredit, setUnpaidCredit] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [creditsHistory, setCreditsHistory] = useState({});
  const [swingScoreReports, setSwingScoreReports] = useState([]);

  const [open, setOpen] = useState(false);
  const [activeSubs, setActiveSubs] = useState(0);

  const [activeTable, setActiveTable] = useState(0);

  const [purchaseResultDialog, setPurchaseResultDialog] = useState({
    open: false,
    isSuccessfull: false,
  });

  const loadData = async () => {
    try {
      setIsLoading(true);
      const firebaseUser = firebase.auth().currentUser;
      setId(firebaseUser.uid);
      let userResponse = await axiosWithToken(
        `${functionBaseUrl}/api/users/${firebaseUser.uid}`,
        {
          method: "GET",
        }
      );
      setUser(userResponse?.data);

      let response = await axiosWithToken(
        `${functionBaseUrl}/api/v1/unpaidCredits/coach/${firebaseUser.uid}`,
        {
          method: "GET",
        }
      );
      if (response?.data?.credits?.length > 0) {
        setAmount(response?.data?.credits[0]?.amount);
        setCredits(response?.data?.credits[0]?.creditCount);
        setUnpaidCredit(response?.data?.credits[0]);
        setCustom(true);
      }

      const creditsHistoryResponse = await axiosWithToken(
        `${functionBaseUrl}/api/v1/credits/coach/${firebaseUser.uid}`,
        {
          method: "GET",
        }
      );
      setCreditsHistory(creditsHistoryResponse?.data);

      const invoicesResponse = await axiosWithToken(
        `${functionBaseUrl}/api/v1/appliedCredits/coach/${firebaseUser.uid}`,
        {
          method: "GET",
        }
      );
      setInvoices(invoicesResponse?.data?.credits);

      const swingScoreReportsResponse = await axiosWithToken(
        `${functionBaseUrl}/api/swing-score-report/v1/report-list`,
        {
          method: "POST",
          data: { coachId: firebaseUser.uid },
        }
      );
      setSwingScoreReports(swingScoreReportsResponse?.data);

      const activeSubsRes = await axiosWithToken(
        `${functionBaseUrl}/api/subsCount/${firebaseUser.uid}`,
        {
          method: "GET",
        }
      );
      setActiveSubs(activeSubsRes?.data?.activeSubs);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleDownloadPdf = async (link) => {
    try {
      const response = await axios.get(link, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const a = document.createElement("a");
      a.href = url;
      a.download = "Swing Score Report.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  async function handleResult() {
    if (custom) {
      await axiosWithToken(`${functionBaseUrl}/api/v1/credits`, {
        method: "PATCH",
        data: { creditId: unpaidCredit?.id, info: { status: "complete" } },
      })
        .then(async () => {
          setPurchaseResultDialog({ open: true, isSuccessfull: true });
          setOpen(false);
        })
        .catch(async (error) => {
          setPurchaseResultDialog({ open: true, isSuccessfull: false });
          setOpen(false);
        });
    } else {
      let input = {
        amount: amount,
        creditCount: credits,
        status: "complete",
        userId: id,
        coachId: id,
        transactionType: "in",
        creditType,
      };

      await axiosWithToken(`${functionBaseUrl}/api/v1/credits`, {
        method: "POST",
        data: input,
      })
        .then(async () => {
          setPurchaseResultDialog({ open: true, isSuccessfull: true });
          setOpen(false);
        })
        .catch(async (error) => {
          setPurchaseResultDialog({ open: true, isSuccessfull: false });
          setOpen(false);
        });
    }
  }

  function handlePackageChange(amount) {
    setAmount(0);
    if (creditType === "reportCredits") {
      if (amount === 250) {
        setCredits(5);
      } else if (amount === 475) {
        setCredits(10);
      } else if (amount === 900) {
        setCredits(20);
      } else if (amount === 1275) {
        setCredits(30);
      } else if (amount === 1600) {
        setCredits(40);
      } else if (amount === 1875) {
        setCredits(50);
      } else {
        setCredits(0);
      }
    } else if (creditType === "studentCredits") {
      if (amount === 300) {
        setCredits(30);
      } else if (amount === 500) {
        setCredits(50);
      } else if (amount === 700) {
        setCredits(70);
      } else if (amount === 1800) {
        setCredits(180);
      } else {
        setCredits(0);
      }
    }
    setAmount(amount);
  }

  const tableHeader = [
    {
      field: "userName",
      title: "Golfer",
      render: (rowData) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {rowData.userData?.picture ? (
            <img
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              src={rowData.userData?.picture}
              alt="avatar"
            />
          ) : (
            <></>
          )}
          <div>{rowData?.userName}</div>
        </div>
      ),
    },
    {
      field: "createdAt",
      title: "Date",
      render: (rowData) => (
        <>{moment(rowData.createdAt).format("MM/DD/YYYY")}</>
      ),
      defaultSort: "desc",
    },
    {
      field: "clubs",
      title: "Clubs",
      render: (rowData) => {
        return <div>{rowData?.clubName}</div>;
      },
    },
    {
      field: "score",
      title: "Score",
      render: (rowData) => {
        const score = rowData?.overallScore;
        return (
          <span
            style={{
              color: "#026AA2",
              background: "#F0F9FF",
              borderRadius: "16px",
              padding: "2px 8px",
            }}
          >
            {score}
          </span>
        );
      },
    },
    {
      field: "userEmail",
      title: "Email address",
    },
    {
      title: "Action",
      render: (rowData) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <CloudDownloadOutlinedIcon
              onClick={() => handleDownloadPdf(rowData.reportURL)}
              style={{ color: "#475467", cursor: "pointer" }}
            />

            <a href={rowData?.reportURL} target="_blank">
              <VisibilityOutlinedIcon
                style={{ color: "#475467", cursor: "pointer" }}
              />
            </a>
          </div>
        );
      },
    },
  ];

  const studentTableHeader = [
    {
      field: "userName",
      title: "Golfer",
      render: (rowData) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {rowData?.userData?.picture && (
            <img
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              src={rowData.userData.picture}
              alt="avatar"
            />
          )}

          <div>
            {rowData?.userData?.firstName} {rowData?.userData?.lastName}
          </div>
        </div>
      ),
    },
    {
      field: "createdAt",
      title: "Date",
      render: (rowData) => (
        <>{moment(rowData.createdAt).format("MM/DD/YYYY")}</>
      ),
      defaultSort: "desc",
    },

    {
      field: "userEmail",
      title: "Email address",
    },
    {
      field: "method",
      title: "Method",
      render: (rowData) => {
        return (
          <span
            style={
              rowData.method === "Auto-Renewal"
                ? {
                    color: "#026AA2",
                    background: "#F0F9FF",
                    borderRadius: "16px",
                    padding: "2px 8px",
                  }
                : {
                    color: "#3538CD",
                    background: "#EEF4FF",
                    borderRadius: "16px",
                    padding: "2px 8px",
                  }
            }
          >
            {rowData?.method || "Manual Upgrade"}
          </span>
        );
      },
    },
    /*{
      title: "Action",
      render: (rowData) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <CloudDownloadOutlinedIcon
              style={{ color: "#475467", cursor: "pointer" }}
            />
          </div>
        );
      },
    },*/
  ];

  const classes = useStyles();

  const handleActiveTable = (state) => {
    setActiveTable(state);
  };

  const handleAddCredits = (state) => {
    setCreditType(state);
    if (state === "reportCredits") {
      setCredits(5);
      setAmount(250);
    } else {
      setCredits(30);
      setAmount(300);
    }
    setOpen(true);
  };

  const activeTableStyle = (state) => {
    const styleObj = {
      padding: "8px 12px",
      fontWeight: "600",
      borderRadius: "64px",
      margin: "20px 10px",
    };
    if (activeTable === state) {
      styleObj.background = "#F9F5FF";
      styleObj.color = "#30077C";
    } else {
      styleObj.color = "#667085";
    }

    if (activeTable !== state) {
      styleObj.cursor = "pointer";
    }

    return styleObj;
  };

  return (
    <>
      {isLoading && (
        <Container
          maxWidth="xs"
          style={{ textAlign: "center", paddingTop: "50px" }}
        >
          <CircularProgress />
        </Container>
      )}
      {!isLoading && (
        <Container component="main" style={{ paddingTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.root}>
                <Typography variant="h4" className={classes.heading}>
                  Credits
                </Typography>
                <Typography variant="body2" className={classes.text}>
                  Support your students practicing alone.
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <Typography variant="body2" className={classes.text}>
                  Your wallets
                </Typography>
                <PurchaseHistoryButton />
              </div>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <CreditsOverview
                name="Swing Score Credits"
                creditNumber={user?.reportCredits || 0}
                onAdd={() => handleAddCredits("reportCredits")}
                perks={[
                  "Identify strengths",
                  "Most important opportunities",
                  "Measure consistency",
                  "Identify swing type",
                  "Detailed motion data",
                ]}
                state={
                  swingScoreReports?.length
                    ? swingScoreReports?.length +
                      " swing score reports generated"
                    : "You haven’t generated any swing score reports yet"
                }
              />

              <CreditsOverview
                name="Student Credits"
                creditNumber={user?.creditCount || 0}
                onAdd={() => handleAddCredits("studentCredits")}
                perks={[
                  "Unlimited swings",
                  "Handsfree Mode",
                  "2D & 3D comparison modes",
                  "Library of 50+ practice drills",
                  "Drawing Tools",
                ]}
                state={
                  (activeSubs || 0) + " students enrolled into auto renew."
                }
                background="#F9FAFB"
              />
            </div>

            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <Typography className={classes.heading}>
                <div className={classes.container}>
                  <img src="/images/icons/users-right.png" />
                  &nbsp; Recent usage
                </div>
              </Typography>

              <div style={{ display: "flex", gap: "30px" }}>
                <div
                  style={activeTableStyle(0)}
                  onClick={() => handleActiveTable(0)}
                >
                  Swing Score Reports
                </div>
                <div
                  style={activeTableStyle(1)}
                  onClick={() => handleActiveTable(1)}
                >
                  Student Credits
                </div>
              </div>

              {activeTable === 0 && (
                <MaterialTable
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  icons={tableIcons}
                  columns={tableHeader}
                  data={swingScoreReports}
                  options={{
                    selection: false,
                    sorting: true,
                    search: false,
                    paging: true,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    showTitle: false,
                    toolbar: false,
                    exportAllData: true,
                    exportButton: true,
                    exportFileName: "Swing Score Reports",
                  }}
                />
              )}

              {activeTable === 1 && (
                <MaterialTable
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  key="credits"
                  icons={tableIcons}
                  columns={studentTableHeader}
                  data={invoices}
                  options={{
                    selection: false,
                    sorting: true,
                    search: false,
                    paging: true,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    showTitle: false,
                    toolbar: false,
                    exportAllData: true,
                    exportButton: true,
                    exportFileName: "Credits",
                  }}
                />
              )}
            </Grid>
            <BuyCredits
              open={open}
              close={() => setOpen(false)}
              handleResult={handleResult}
              amount={amount}
              credits={credits}
              custom={custom}
              handlePackageChange={handlePackageChange}
              user={user}
              creditType={creditType}
            />
          </Grid>
          <PurchaseResultDialog
            open={purchaseResultDialog.open}
            onClose={() => {
              setPurchaseResultDialog({ open: false, isSuccesfull: true });
              window.location.reload();
            }}
            isSuccessfull={purchaseResultDialog.isSuccessfull}
            credits={credits}
            creditType={creditType}
          />
        </Container>
      )}
    </>
  );
}

export const Credits = CreditsComponent;
