import React from "react";

export const ReportChartPage = ({ pageRef, totalPages }) => {
  return (
    <div
      ref={pageRef}
      style={{
        width: "800px",
        height: "1150px",
        margin: "20px auto",
        // padding: "80px",
        backgroundColor: "#fff",
        color: "#333",
        fontSize: "11px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 style={{ fontSize: "16px" }}>Swing Score Report</h2>
        <img src="/images/Logo.svg" alt="logo" />
      </div> */}

      <img
        style={{ transform: "rotate(-90deg)", marginTop: "270px" }}
        src={"/images/pdf-report/swing-score-report-pdf-chart-1.png"}
        alt="chart"
      />

      <div
        style={{
          marginTop: "230px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ marginRight: "50px", fontSize: "10px" }}>
          Page {2} of {totalPages}
        </div>
      </div>
    </div>
  );
};
