import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Paper,
} from "@material-ui/core";
import s from "./style.module.css";

import DownloadIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckIcon from "@material-ui/icons/Check";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import UndoIcon from "@material-ui/icons/Undo";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../../../common/firebase";
import Swal from "sweetalert2";
import moment from "moment";

export const PurchaseHistoryButton = () => {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const itemsPerPage = 10;

  const columns = [
    {
      accessorKey: "blank",
      header: "",
    },
    // {
    //   accessorKey: "id",
    //   header: "Invoice",
    //   cell: (info) => `#${info.getValue().slice(0, 4)}`,
    // },
    {
      accessorKey: "creditCount",
      header: "Order",
      cell: (info) => {
        const creditType = info.row.original.creditType;
        return `${info.getValue()} ${
          creditType === "reportCredits" ? "Swing Score" : "Student"
        } Credits`;
      },
    },
    {
      accessorKey: "amount",
      header: "Price",
      cell: (info) => `$${info.getValue()}`,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => (
        <span className={`${s[info.getValue().toLowerCase()]} ${s.status}`}>
          {statusToIcon[info.getValue().toLowerCase()]}{" "}
          {statusToValue[info.getValue()]}
        </span>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Date",
      cell: (info) => `${moment(info.getValue()).format("MM-DD-YYYY")}`,
    },
    // {
    //   accessorKey: "receipt",
    //   header: "Receipt",
    //   cell: () => (
    //     <IconButton>
    //       <DownloadIcon />
    //     </IconButton>
    //   ),
    // },
  ];

  const statusToIcon = {
    refund: <UndoIcon fontSize="small" />,
    pending: <MoreHorizIcon fontSize="small" />,
    complete: <CheckIcon fontSize="small" />,
    cancelled: <CloseIcon fontSize="small" />,
  };

  const statusToValue = {
    refund: "refund",
    pending: "pending",
    complete: "paid",
    cancelled: "cancelled",
  };

  const totalPages = Math.ceil(invoices.length / itemsPerPage);

  const paginatedData = invoices.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const table = useReactTable({
    data: paginatedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      sorting: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    },
  });

  const loadData = async () => {
    try {
      setIsLoading(true);

      const firebaseUser = firebase.auth().currentUser;
      const userId = firebaseUser.uid;

      let response = await axiosWithToken(
        `${functionBaseUrl}/api/v1/credits/coach/${userId}`,
        {
          method: "GET",
        }
      );
      const result = response?.data?.credits
        .filter((el) => el.transactionType === "in")
        .sort((a, b) => b.createdAt - a.createdAt);

      setInvoices(result);
      setCurrentPage(0);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: '<p style="font-size:70%;">Error loading data</p>',
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: true,
      });
    }
  };

  useEffect(() => {
    if (open) loadData();
  }, [open]);

  const changePage = (dir) => {
    if (dir === "forward" && currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    } else if (dir === "back" && currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <>
      <div onClick={() => setOpen(true)} className={s.purchaseHistoryButton}>
        Purchase history
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              fontFamily: "Manrope",
            }}
          >
            <div
              style={{
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Purchase History
            </div>
            <CloseIcon
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          {isLoading ? (
            <div
              style={{
                height: "700px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/images/loading.gif"
                alt="loading"
                width="70px"
                height="70px"
              />
            </div>
          ) : (
            <>
              <Paper className={s.tableContainer}>
                <table className={s.table}>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id} className={s.headerCell}>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Paper>

              <div className={s.paginationContainer}>
                <IconButton
                  onClick={() => changePage("back")}
                  disabled={currentPage === 0}
                >
                  <ArrowBackIcon fontSize="small" />
                </IconButton>

                <span>
                  Page {currentPage + 1} of {totalPages}
                </span>

                <IconButton
                  onClick={() => changePage("forward")}
                  disabled={currentPage === totalPages - 1}
                >
                  <ArrowForwardIcon fontSize="small" />
                </IconButton>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
