import React, { useState, useRef, useEffect } from 'react';
import { 
  Grid,
  Button,
} from '@material-ui/core';
import { axiosWithToken, functionBaseUrl } from '../../common/firebase';

import VideoPlayer from './components/video_player'
import SwingDetails from './components/swing_details/swing_details';
import ChatPanel from './components/chat_panel';
import SwingSelect from './components/swing_select';
import JsonLoader from './components/json_loader';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { set } from 'date-fns';
const https = require("https");
const fs = require("fs");

function LLMLlamoChat() {  
  const [jsonData, setJsonData] = useState([]); 
  const [fileIdx, setFileIdx] = useState(0);
  const [messages, setMessages] = useState([]);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState('');
  const [goldsets, setGoldsets] = useState([]);
  const [goldset, setGoldset] = useState('');
  const [toggleDetails, setToggleDetails] = useState(false);
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [selectSwingIdx, setSelectSwingIdx] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [goldsetDescription, setGoldsetDescription] = useState("");

  const videoRef = useRef(null);
  
  const [defaultParameters, setDefaultParameters] = useState({
    temperature: 1,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0
  })

  useEffect(() => {    
    async function fetchData() {
      const avail_goldsets = await getAvailableGoldsets()      
      if (avail_goldsets && avail_goldsets.length > 0) {
        setGoldsets(avail_goldsets)            
      }
    }
    fetchData()
  }, []);

  async function getAvailableGoldsets() {
    return axiosWithToken(functionBaseUrl+'/api/llm/get-available-goldsets', {    
      method: 'get',      
    }).then(response => {
      let data = response.data            
      return data.available_files
    })
    .catch(err => {
      console.log(err.response?.data)
      return []
    });   
  }

  async function sendRequest(prompt, videoUrl, newMessage) {
    setLoadingMessage(true);
    const url = "https://gpu.sportsbox.ai:40040/chat";

    try {
      const response = await axios.post(
        url,
        new URLSearchParams({ prompt, video_url: videoUrl }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );

      const message = {
        message: response.data.response,
        //csv_attachment: csv_attachment,
        sentTime: moment().valueOf(),
        sender: "assistant",    
        direction: "incoming",
      }

      setMessages([...messages, newMessage, message]);
      setLoadingMessage(false);
      return response.data;
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
      Swal.fire({
        title: '<p style="font-size:70%;">Error sending request</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
      setLoadingMessage(false);
      setMessages([...messages]);
    } 
  }

  const handleGoldsetChange = (event) => {
    setGoldset(event.target.value);
  }

  const handleExport = () => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(messages, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "chat_data.json");
    document.body.appendChild(downloadAnchorNode); // Required for Firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();        
  }

  useEffect(() => {
    if (jsonData.length > 0 && videoRef.current) {
      videoRef.current.src = jsonData[fileIdx].video_url;
      videoRef.current.load(); // Reload the video element
    }
  }, [fileIdx, jsonData]);

  const handleToggleDetails = () => {
    setToggleDetails(!toggleDetails);
  }

  const setJsonParameters = (params) => {    
    if (params != null) {      
      setDefaultParameters(params);
    }
  }
  
  return (<>
    <div style={{ padding: 20, overflow: "hidden"}}>      
      <div style={{ padding: 20, overflow: "hidden"}}>
        {jsonData?.length <= 0 && (
          <JsonLoader 
            goldsets={goldsets}
            goldset={goldset}
            handleGoldsetChange={handleGoldsetChange}
            setJsonData={setJsonData}
            fileIdx={fileIdx}
            setJsonParameters={setJsonParameters}
            setGoldsetDescription={setGoldsetDescription}
            sendRequest={sendRequest}
          />
        )}
        {jsonData?.length > 0 && (
          <Button onClick={()=>{ setJsonData([]); setMessages([]); setFileIdx(0) }}>Back</Button>
        )}
        <Grid
          container
          spacing={2}
          padding={10}
        >
          <Grid
            item
            xs={6}
          >
            {
              jsonData?.length > 0 && !toggleDetails && (
                <VideoPlayer
                  jsonData={jsonData}
                  fileIdx={fileIdx}
                  setFileIdx={setFileIdx}
                  toggleDetails={toggleDetails}
                  setToggleDetails={setToggleDetails}
                  videoRef={videoRef}
                />
              )
            }
            {
              jsonData?.length > 0 && toggleDetails && (
                <SwingDetails
                  jsonData={jsonData}
                  setJsonData={setJsonData}
                  fileIdx={fileIdx}
                  defaultParameters={defaultParameters}
                  setDefaultParameters={setDefaultParameters}
                  handleToggleDetails={handleToggleDetails}
                  goldsetDescription={goldsetDescription}
                />                
              )
            }
          </Grid>
          <Grid
            item
            xs={6}
          >
            {
              jsonData?.length > 0 && (
                <ChatPanel
                  jsonData={jsonData}
                  messages={messages}
                  setMessages={setMessages}
                  model={model}
                  models={models}
                  setModel={setModel}
                  selectSwingIdx={selectSwingIdx}
                  setSelectModalOpen={setSelectModalOpen}
                  getResponse={sendRequest}
                  loadingMessage={loadingMessage}
                  videoRef={videoRef}
                  handleExport={handleExport}
                />
              )
            }
          </Grid>
          <SwingSelect
            selectModalOpen={selectModalOpen}
            setSelectModalOpen={setSelectModalOpen}
            jsonData={jsonData}
            setSelectSwingIdx={setSelectSwingIdx}
            selectSwingIdx={selectSwingIdx}
          />
        </Grid>      
      </div>
    </div>
  </>);
}

export const LLMLlamoChatPage = LLMLlamoChat;
