import React from 'react';
import { Typography, Button } from '@material-ui/core';
import KeyValueList from './key_value';
import { ParameterSlider } from './model_params';

function SwingDetails({ 
  jsonData, setJsonData, 
  fileIdx, defaultParameters, 
  setDefaultParameters, handleToggleDetails,
  goldsetDescription
}) {

  const handleNotesChange = (notes) => {
    const newJsonData = jsonData
    jsonData[fileIdx].notes = notes
  
    setJsonData(newJsonData);
  };

  const handleTempChange = (event, val) => {
    const id = event.target.id;
    setDefaultParameters((prev) => ({
      ...prev,
      [id]: val,
    }));
  };

  return (
    <div style={{
        height: '80vh', // Full viewport height to center vertically                  
        overflow: 'auto', // Enable scrolling when content overflows
        textAlign: 'center', // Center text inside the div (optional)
      }}>
        <Button variant='contained' color='primary' onClick={handleToggleDetails}>Show swing</Button>
        <div>
          <br/>
            {goldsetDescription}
          <br/>
            <KeyValueList data={jsonData[fileIdx]?.indicators || {}}/>          
          <br/>
            <ParameterSlider defaultParameters={defaultParameters} handleTempChange={handleTempChange}/>            
        </div>
      </div>
  );
}

export default SwingDetails;
