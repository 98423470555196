import React from "react";

export const CreditsOverview = ({
  name,
  perks,
  state,
  creditNumber,
  onAdd,
  background = "#FFFFFF",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "49%",
        border: "#EAECF0 1px solid",
        borderRadius: "12px",
        padding: "24px",
        fontFamily: "Manrope",
        fontSize: "14px",
        background,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>{name}</div>
          <div style={{ fontSize: "24px", fontWeight: "bold" }}>
            {creditNumber}
          </div>
        </div>

        <span
          style={{
            background: "#5C0DEE",
            borderRadius: "64px",
            height: "fit-content",
            padding: "10px 16px",
            color: "white",
            cursor: "pointer",
          }}
          onClick={onAdd}
        >
          Add Credits
        </span>
      </div>
      <div>{state}</div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        {perks.map((el) => {
          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
                width: "45%",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src="/images/icons/check_icon.png" />
              <div>{el}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
